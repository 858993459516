main{
    padding-bottom: 150px;
}
.about-2-section1 {
    padding-top: 40px;
    padding-bottom: 72px;
}

.about-2-section1-content h1 {
    font-family: 'SF Pro Display';
    font-style: normal;
    font-weight: 700;
    font-size: 42px;
    line-height: 46px;
    color: #3C3C3C;
    padding-bottom: 40px;
}

.about-2-section1-content p {
    font-family: 'SF Pro Display';
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    color: #3C3C3C;
    width: 668px;
}

.about-2-section2-content {
    display: flex;
    align-items: center;
    padding-left: 124px;
    padding-right: 124px;
}

.ab-2-content-image-sec2 {
    margin-right: 67px;
}

.ab-2-content-text-sec2 h3 {
    font-family: 'SF Pro Display';
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    color: #3C3C3C;
    padding-bottom: 15px;
}

.ab-2-content-text-sec2 {
    font-family: 'SF Pro Display';
    font-style: normal;
    font-weight: 700;
    font-size: 42px;
    line-height: 46px;
    color: #3C3C3C;
    max-width: 348px;
}

.about-2-section2 {
    padding-bottom: 115px;
}

.about-2-section3-content h2 {
    text-align: center;
    font-family: 'SF Pro Display';
    font-style: normal;
    font-weight: 700;
    font-size: 42px;
    line-height: 46px;
    text-align: center;
    color: #3C3C3C;
    padding-bottom: 45px;
}

.row-col {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.row-col h2 {
    font-family: 'SF Pro Display';
    font-style: normal;
    font-weight: 500;
    font-size: 38px;
    line-height: 46px;
    text-align: center;
    color: #3C3C3C;
    padding-bottom: 15px;
}

.row1-col1 p {
    font-family: 'SF Pro Display';
    font-style: normal;
    font-weight: 500;
    font-size: 22px;
    line-height: 26px;
    text-align: center;
    color: #3C3C3C;
    max-width: 216px;
}

.about-2-sec3-row-1 {
    justify-content: space-between;
}

.row2-col2 h2 {
    width: 248px;
}

.about-2-sec3-row-2 {
    padding-top: 45px;
    display: flex;
    justify-content: space-around;
}

.max-width {
    max-width: 349px;
    text-align: center;
}

.about-2-section3 {
    padding-bottom: 150px;
}
.about-2-section3-content{
    padding-left: 129px;
}

.about-2-section4-content {
    display: flex;
    gap: 110px;
}

.ab-2-sec4-left h2 {
    font-family: 'SF Pro Display';
    font-style: normal;
    font-weight: 700;
    font-size: 42px;
    line-height: 46px;
    text-align: center;
    color: #3C3C3C;
    padding-bottom: 40px;
    text-align: left;
}

.ab-2-sec4-left p {
    font-family: 'SF Pro Display';
    font-style: normal;
    font-weight: 500;
    font-size: 22px;
    line-height: 26px;
    color: #3C3C3C;
    max-width: 600px;
}

.ab-dark-button {
    font-family: 'SF Pro Display',sans-serif;
    font-size: 22px;
    font-weight: 500;
    line-height: 22px;
    letter-spacing: 0px;
    text-align: left;
    text-decoration: none;
    color: #fff;
    padding: 15px 18px;
    background-color: #3C3C3C;
    border-radius: 5px;
    display: inline-block;
}
.ab-dark-button:hover{
    color: #fff;
}