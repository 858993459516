.help-center {
    padding: 40px 0;
}

.help-center h1 {
    font-family: 'SF Pro Display', sans-serif;
    font-size: 24px;
    font-weight: 700;
    line-height: 22px;
    letter-spacing: 0px;
    text-align: left;
    color: #3C3C3C;
    margin-bottom: 30px;
}

.help-center .help-center-tabs-wrapper {
    display: inline-flex;
    border-bottom: 1px solid #EFEFF4;
}

.help-center .help-center-tabs-wrapper .nav-link {
    border: 0;
    font-family: 'SF Pro Display', sans-serif;
    font-size: 16px;
    font-weight: 500;
    line-height: 22px;
    letter-spacing: 0px;
    text-align: left;
    color: #3C3C3C4D;
    padding: 0 5px 5px;
}

.help-center .help-center-tabs-wrapper .nav-link.active {
    color: #3C3C3C;
    border-bottom: 2px solid #3C3C3C;
}

.help-center .tab-content {
    margin-top: 30px;
}

.help-center .tab-content h2 {
    font-family: 'SF Pro Display', sans-serif;
    font-size: 20px;
    font-weight: 700;
    line-height: 22px;
    letter-spacing: 0px;
    text-align: left;
    color: #3C3C3C;
}

.help-center .tab-content .accordion-toggler[aria-expanded="true"] {
    display: none;
}

.help-center .tab-content ul {
    list-style-type: none;
}

.help-center .tab-content ul li:not(.accordion-toggler) {
    padding: 20px 0;
    border-bottom: 1px solid #EFEFF4;
    font-family: 'SF Pro Display',sans-serif;
    font-size: 16px;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: 0px;
    text-align: left;
    color: #3C3C3C;
}
.help-center .tab-content ul li:last-child{
    border: 0;
}
.help-center .tab-content ul li.accordion-toggler {
    font-family: 'SF Pro Display',sans-serif;
    font-size: 16px;
    font-weight: 700;
    line-height: 22px;
    letter-spacing: 0px;
    text-align: left;
    text-decoration: underline;
    color: #3C3C3C;
    margin-top: 20px;
    cursor: pointer;
}
.row-gap-58{
    row-gap: 58px;
}