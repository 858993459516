/* vehicle-list */
.vehicle-list {
    padding: 40px 0;
    overflow-x: hidden;
    min-height: 900px;
}

.vehicle-list h1 {
    font-family: 'SF Pro Display', sans-serif;
    font-size: 24px;
    font-weight: 700;
    line-height: 22px;
    letter-spacing: 0px;
    text-align: left;
    color: #3C3C3C;
    margin-bottom: 20px;
}

.vehicle-list .show-filter-wrapper,
.vehicle-list .show-filter-wrapper a {
    font-family: 'SF Pro Display', sans-serif;
    font-size: 14px;
    font-weight: 500;
    line-height: 22px;
    letter-spacing: 0px;
    text-align: left;
    color: #666;
    text-decoration: none;
    user-select: none;
}

.vehicle-list .show-filter-wrapper .show-filter {
    margin-left: 20px;
    display: flex;
    gap: 10px;
}

.vehicle-list .show-filter-wrapper .show-filter a {
    padding: 0 5px;
    border-radius: 5px;
}

.vehicle-list .show-filter-wrapper .show-filter a.active,
.vehicle-list .show-filter-wrapper .show-filter a:hover {
    background-color: #F4F4F4;
}

.car-year-wrapper {
    position: relative;
    background-color: #fff;
    border-radius: 10px;
}

input[type="range"] {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    width: 100%;
    outline: none;
    position: absolute;
    margin: auto;
    /* top: 0; */
    bottom: 0;
    background-color: transparent;
    pointer-events: none;
}

.slider-track {
    background-color: #D8D8D8;
    width: 100%;
    height: 1.2px;
    margin: auto;
    top: 0;
    bottom: 0;
    border-radius: 5px;
}

input[type="range"]::-webkit-slider-runnable-track {
    -webkit-appearance: none;
    height: 0px;
}

input[type="range"]::-moz-range-track {
    -moz-appearance: none;
    height: 0px;
}

input[type="range"]::-ms-track {
    appearance: none;
    height: 0px;
}

input[type="range"]::-webkit-slider-thumb {
    -webkit-appearance: none;
    height: 16px;
    width: 16px;
    background-color: #F8CC0D;
    cursor: pointer;
    margin-top: -9px;
    pointer-events: auto;
    border-radius: 50%;
}

input[type="range"]::-moz-range-thumb {
    -webkit-appearance: none;
    height: 16px;
    width: 16px;
    cursor: pointer;
    border-radius: 50%;
    background-color: #F8CC0D;
    pointer-events: auto;
}

input[type="range"]::-ms-thumb {
    appearance: none;
    height: 16px;
    width: 16px;
    cursor: pointer;
    border-radius: 50%;
    background-color: #F8CC0D;
    pointer-events: auto;
}

.values {
    position: relative;
    margin: auto;
    padding: 10px 0;
    font-weight: 500;
    font-size: 14px;
    color: #3C3C3C;
}

.filters {
    gap: 15px;
}

.filters .offcanvas-backdrop.show {
    opacity: 0;
    top: 77px !important;
}

.filters .offcanvas {
    position: absolute;
    bottom: unset;
    top: -39px;
    border-left: none;
    box-shadow: 0px 0px 4px 2px #3C3C3C1A;
    overflow-y: auto;
}

.filters .offcanvas::-webkit-scrollbar {
    width: 0px;
}

.filters .offcanvas-body {
    margin-top: 6px;
}

.filters .sort-filter,
.filters .more-filters {
    gap: 14px;
    display: inline-flex;
    font-family: 'SF Pro Display', sans-serif;
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: 0px;
    text-align: left;
    color: #3C3C3C;
    align-items: center;
    border: 1px solid #D8D8D8;
    border-radius: 30px;
    padding: 10px 15px;
    background-color: #fff;
}

.filters .sort-filter:hover,
.filters .more-filters:hover {
    background-color: #F4F4F4;
}

.filters .more-filters {
    position: relative;
}

.filters .sort-filter::after,
.filters .more-filters::after {
    content: '';
    margin-left: 13px;
    display: inline-flex;
    width: 11px;
    height: 9px;
    align-self: center;
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    background-image: url(../img/Arrow\ -\ Down.png);
    border: unset;
}

.filters .sort-filter.show::after {
    background-image: url(../img/ArrowTop.png);
}

.filters .more-filters .filters-count {
    position: absolute;
    width: 22px;
    height: 22px;
    background: #EC4E5F;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    top: -11px;
    right: 0;
    font-family: 'SF Pro Display', sans-serif;
    font-size: 14px;
    font-weight: 500;
    line-height: 22px;
    letter-spacing: 0px;
    text-align: center;
    color: #fff;
}

.sort-filter-dropdown .dropdown-menu.show {
    display: block;
    width: 100%;
    border: 0;
    border-radius: 10px;
}

.filters .filters-header .btn-close {
    width: 24px;
    height: 24px;
}

.filters .offcanvas {
    padding: 15px 15px 40px 25px;
    background-color: #fff;
}

.filters .filters-body h3 {
    font-family: 'SF Pro Display', sans-serif;
    font-size: 20px;
    font-weight: 700;
    line-height: 22px;
    letter-spacing: 0px;
    text-align: left;
    color: #3C3C3C;
    margin-bottom: 30px;
}

.filters .filters-form {
    display: flex;
    flex-direction: column;
    gap: 25px;
}

.filters .filters-form label {
    font-family: 'SF Pro Display', sans-serif;
    font-size: 16px;
    font-weight: 700;
    line-height: 22px;
    letter-spacing: 0px;
    text-align: left;
    color: #3C3C3C;
    margin-bottom: 10px;
    display: block;
    width: 100%;
}

.filters .filters-form .ui-selectmenu-button.ui-button,
.filters .filters-form select {
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    font-family: 'SF Pro Display', sans-serif;
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: 0px;
    text-align: left;
    color: #3C3C3C;
    align-items: center;
    border: 1px solid #D8D8D8;
    border-radius: 5px;
    padding: 10px 15px;
    background-color: #fff;
    width: 100%;
    -moz-appearance: none;
    /* Firefox */
    -webkit-appearance: none;
    /* Safari and Chrome */
    appearance: none;
}

.filters .filters-form select option {
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    color: #3C3C3C;
}

.filters .filters-form select option:hover,
.filters .filters-form select option:selected {
    background-color: #F4F4F4 !important;
}

.filters .filters-form select:hover,
.filters .filters-form select:focus {
    outline: none;
    box-shadow: none;
}

.filters .filters-form .clear-input {
    outline: none;
    border: none;
    background-position: center;
    background-size: 100%;
    width: 15px;
    height: 15px;
    border-radius: 50%;
    background-image: url(../img/Info\ Circle.png);
}

.filters .filters-form label {
    display: flex;
    align-items: center;
    gap: 10px;
}

/* vehicles-wrapper */
.vehicles-wrapper {
    padding: 30px 0 40px;
    row-gap: 30px;
}

/* vehicle single item */
.vehicle {
    border-radius: 10px;
    overflow: hidden;
    box-shadow: 0px 4px 4px 0px #EBEBEB;
    text-decoration: none;
    display: block;
}

.vehicle .vehicle-info {
    padding: 20px 20px 16px;
}

.vehicle .vehicle-info .head {
    gap: 18px;
}

.vehicle-image {
    position: relative;
}

.vehicle-image img {
    width: 100%;
    max-height: 210px;
}

.like-btn {
    position: absolute;
    top: 16px;
    right: 15px;
    z-index: 22;
}

.like-btn.liked svg path {
    fill: #F61E1E;
}

.vehicle .vehicle-info .head h4 {
    font-family: 'SF Pro Display', sans-serif;
    font-size: 16px;
    font-weight: 700;
    line-height: 22px;
    letter-spacing: 0px;
    text-align: left;
    margin-top: 3px;
    color: #3C3C3C;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    max-width: 16ch;
}

.vehicle .vehicle-info .rate-wrapper {
    gap: 5px;
    font-family: 'SF Pro Display', sans-serif;
    font-size: 14px;
    font-weight: 500;
    line-height: 22px;
    letter-spacing: 0px;
    text-align: left;
    color: #3C3C3C;
}

.vehicle-info .horizontal-divider {
    border: 1px solid #EFEFF4;
    display: block;
    margin: 6px 0 13px;
}

.vehicle-info .price-wrapper {
    font-family: 'SF Pro Display', sans-serif;
    font-size: 16px;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: 0px;
    text-align: left;
    color: #3C3C3C;
}

.vehicle-info .price-wrapper .seperator {
    margin: 0 3px;
    font-weight: 700;
}

.vehicle-info .price-wrapper .total-price {
    margin-right: 3px;
}

.pagination .page-numbers {
    font-family: 'SF Pro Display', sans-serif;
    font-size: 16px;
    font-weight: 500;
    line-height: 22px;
    letter-spacing: 0px;
    text-align: left;
    color: #3C3C3C;
    text-decoration: none;
    padding: 7px 14px;
    border-radius: 50%;
    background-color: transparent;
    user-select: none;
}

.pagination .nav-links {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 2px;
}

.pagination .nav-links .page-numbers.current {
    background-color: #3C3C3C;
    color: #FFFFFF;
}


@media screen and (max-width:575.9px) {
    .vehicle-image {
        position: relative;
        height: 210px;
    }

    .vehicle-image img {
        position: absolute;
        object-fit: cover;
        width: 100%;
        height: 100%;
    }
}