.header-with-search .dropdown-toggle::after {
    background-image: url(../img/Arrow.svg);
    background-repeat: no-repeat;
    background-size: contain;
    border: 0;
    letter-spacing: 0;
    margin-left: 13px;
    width: 10px;
    height: 6px;
}

.navigationDropdownMenu {
    transform: translate(-195px, 50px) !important;
    width: 215px;
    padding: 13px 10px;
    border-radius: 10px;
    border: 0;
    inset: 0px auto auto 0px !important;
    border-radius: 10px;
}

.navigationDropdownMenu .nav-item .nav-link {
    color: #3C3C3C;
    font-family: 'SF Pro Display',sans-serif;
    font-size: 16px;
    font-weight: 500;
    line-height: 22px;
    letter-spacing: 0px;
    text-align: left;
    padding: 7px 10px;
    border-radius: 10px;
}

.navigationDropdownMenu .nav-item .nav-link:hover{
    background-color: #F4F4F4;
}

header.header-with-search .navbar {
    background-color: transparent;
}

header.header-with-search .navbar-toggler {
    padding: 0;
    border: 0;
    outline: 0;
    box-shadow: none;
    width: 24px;
    height: 24px;
    background-color: transparent;
}

header.header-with-search .navbar-toggler:focus {
    padding: 0;
    border: 0;
    outline: 0;
    box-shadow: none;
}

header.header-with-search .navbar-toggler-icon {
    background-image: url(../img/heroicons-outline_menu.png);
    background-color: transparent;
    width: 24px;
    height: 24px;
}


header.header-with-search .navbar-toggler::after {
    display: none;
}

.navbar {
    padding: 0;
}

.offcanvas {
    padding: 20px 20px 20px 30px;
}

.offcanvas-header {
    justify-content: flex-end;
    padding: 0;
}

.offcanvas-header .btn-close {
    padding: 0;
    margin: 0;
    background-image: url(../img/carbon_close.png);
    width: 40px;
    height: 40px;
    background-size: contain;
    background-repeat: no-repeat;
}

.offcanvas-header .btn-close:focus {
    border: 0;
    outline: 0;
    box-shadow: none;
    transform: none;
}

.offcanvas-body {
    margin-top: 50px;
    padding: 0;
}

.offcanvas-body .navbar-nav {
    gap: 15px;
}

.offcanvas-body .navbar-nav li.nav-item a {
    font-family: 'SF Pro Display', sans-serif;
    font-size: 14px;
    font-weight: 400;
    line-height: 17px;
    letter-spacing: 0px;
    text-align: left;
    color: #3C3C3C;
    padding: 0;
}

.offcanvas-body .navbar-nav li.nav-item.active a {
    font-weight: 700;
}

header.header-with-search.header-with-search {
    position: unset;
    width: 100%;
    z-index: 222;
    border-bottom: 1px solid #EFEFF4
}

/* notifications start*/

header.header-with-search .notification {
    position: relative;
    padding: 5px;
    border-radius: 5px;
}

header.header-with-search .notification:hover {
    background-color: #F4F4F4;
}

header.header-with-search .notification::after {
    content: unset;
}

header.header-with-search .notification.hasNotification::after {
    content: '';
    position: absolute;
    top: 6px;
    right: 6px;
    width: 10px;
    height: 10px;
    border: 1px solid #fff;
    border-radius: 50%;
    background-color: #EC4E5F;
    background-image: none !important;
}

header.header-with-search .notificationsDropdownMenu {
    transform: translate(-360px, 55px) !important;
    width: 400px;
    padding: 0;
    border-radius: 10px;
    border: 0;
    inset: 0px auto auto 0px !important;
}

header.header-with-search .notificationsDropdownMenu li {
    position: relative;
}

header.header-with-search .notificationsDropdownMenu li.new:before {
    content: '';
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: #EC4E5F;
    display: block;
    position: absolute;
    top: 20px;
    left: 10px;
}

header.header-with-search .notificationsDropdownMenu .nots-header .notification-title {
    font-family: 'SF Pro Display', sans-serif;
    font-size: 20px;
    font-weight: 700;
    line-height: 22px;
    letter-spacing: 0px;
    text-align: left;
    color: #3C3C3C;
    margin-bottom: 20px;
}

header.header-with-search .notificationsDropdownMenu .nots-header {
    padding: 30px 30px 0;
}

header.header-with-search .notificationsDropdownMenu .nots-header .tabs {
    gap: 20px;
}

header.header-with-search .notificationsDropdownMenu .nots-header .tabs .tab-item {
    font-family: 'SF Pro Display', sans-serif;
    font-size: 14px;
    font-weight: 500;
    line-height: 22px;
    letter-spacing: 0px;
    text-align: left;
    color: #666666;
    gap: 10px;
    position: relative;
    padding-bottom: 10px;
    cursor: pointer;
}

header.header-with-search .notificationsDropdownMenu .nots-header .tabs .tab-item.active::after {
    content: '';
    height: 2px;
    width: 100%;
    display: block;
    background-color: #3C3C3C;
    position: absolute;
    bottom: 0;
    border-radius: 1px;
}

header.header-with-search .notificationsDropdownMenu .nots-header .tabs .tab-item.active {
    color: #3C3C3C;
}

header.header-with-search .notificationsDropdownMenu .nots-header .tabs .tab-item .tab-notifications-count {
    font-family: 'SF Pro Display', sans-serif;
    font-size: 12px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0px;
    text-align: center;
    padding: 0 5px;
    background-color: #F4F4F4;
    color: #666666;
    border-radius: 8px;
    width: 24px;
}

header.header-with-search .notificationsDropdownMenu .nots-header .tabs .tab-item.active .tab-notifications-count {
    background-color: #3C3C3C;
    color: #fff;
}

header.header-with-search .notificationsDropdownMenu .dropdown-item {
    padding: 20px 30px;
    gap: 15px;
}

header.header-with-search .notificationsDropdownMenu .dropdown-item:hover,
header.header-with-search .notificationsDropdownMenu .dropdown-item:focus {
    background-color: #fff;
}

header.header-with-search .notificationsDropdownMenu .dropdown-item .message-title {
    font-family: 'SF Pro Display', sans-serif;
    font-size: 14px;
    font-weight: 500;
    line-height: 22px;
    letter-spacing: 0px;
    text-align: left;
    color: #3C3C3C;
    margin-bottom: 4px;
}

header.header-with-search .notificationsDropdownMenu .dropdown-item .message-body {
    font-family: 'SF Pro Display', sans-serif;
    font-size: 14px;
    font-weight: 400;
    line-height: 17px;
    letter-spacing: 0px;
    text-align: left;
    color: #666666;
    white-space: initial;
}

header.header-with-search .notificationsDropdownMenu .nots-date {
    padding: 10px 30px;
    background-color: #F4F4F4;
    font-family: 'SF Pro Display', sans-serif;
    font-size: 14px;
    font-weight: 500;
    line-height: 22px;
    letter-spacing: 0px;
    text-align: left;
    color: #666666;
    text-transform: uppercase;
}

header.header-with-search .notificationsDropdownMenu .dropdown-item .time {
    font-family: 'SF Pro Display', sans-serif;
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: 0px;
    text-align: left;
    color: #666666;
}

/* notifications end */

/* message start */
header.header-with-search .message {
    position: relative;
}

header.header-with-search .message::after {
    content: unset;
}

header.header-with-search .message.hasMessage::after {
    content: '';
    position: absolute;
    top: 0px;
    right: 0px;
    width: 10px;
    height: 10px;
    border: 1px solid #fff;
    border-radius: 50%;
    background-color: #EC4E5F;
    background-image: none !important;
    margin: 0;
}

header.header-with-search .messagesDropdownMenu {
    position: absolute;
    inset: 0px auto auto 0px;
    margin: 0px;
    transform: translate(-190px, 55px) !important;
    width: 215px;
    background: #fff;
    border-radius: 10px;
    border: 0;
    padding: 14px 16px 20px;
    inset: 0px auto auto 0px !important;
}

header.header-with-search .messagesDropdownMenu .name {
    font-family: 'SF Pro Text', sans-serif;
    font-size: 14px;
    font-weight: 600;
    line-height: 20px;
    letter-spacing: 0px;
    text-align: left;
    color: #3C3C3C;
}

header.header-with-search .messagesDropdownMenu .last-message {
    font-family: 'SF Pro Text', sans-serif;
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: 0px;
    text-align: left;
    color: #666;
}

header.header-with-search .messagesDropdownMenu .message-item {
    gap: 7px;
    text-decoration: none;
    margin-bottom: 15px;
    padding: 2px;
    border-radius: 10px;
}

header.header-with-search .messagesDropdownMenu .message-item:hover {
    background-color: #F4F4F4;
}

header.header-with-search .messagesDropdownMenu .message-item:focus {
    background-color: #F4F4F4;
}

header.header-with-search .messagesDropdownMenu .message-item img {
    border-radius: 50%;
    width: 32px;
    height: 32px;
}

header.header-with-search .messagesDropdownMenu .all-messages {
    gap: 10px;
    text-decoration: none;
}

header.header-with-search .messagesDropdownMenu .hor-divider {
    border: 1px solid #EFEFF4;
    margin-bottom: 13px;
}

header.header-with-search .messagesDropdownMenu .all-messages span {
    font-family: 'SF Pro Display', sans-serif;
    font-size: 16px;
    font-weight: 500;
    line-height: 22px;
    letter-spacing: 0px;
    text-align: left;
    color: #3C3C3C;
}

#messageModal.modal-dialog,
#messageModal .modal-dialog {
    width: 400px;
}

#messageModal .modal-header {
    border: 0;
}

#messageModal .modal-header-wrapper {
    gap: 7px;
}

#messageModal .modal-header-wrapper img {
    width: 40px;
    height: 40px;
    border-radius: 50%;
}

#messageModal .modal-header-wrapper .modal-title {
    font-family: 'SF Pro Display', sans-serif;
    font-size: 22px;
    font-weight: 700;
    line-height: 20px;
    letter-spacing: 0px;
    text-align: left;
    color: #3C3C3C;
}

#messageModal .modal-body {
    display: flex;
    flex-direction: column;
    padding: 12px;
    border: 0;
}

#messageModal .modal-body .text-message {
    max-width: 275px;
    white-space: initial;
    font-family: 'SF Pro Display', sans-serif;
    font-size: 15px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0px;
    text-align: left;
    color: #3C3C3C;
    padding: 7px 13px;
    background-color: #EFEFF4;
    margin-bottom: 2px;
    display: inline-block;
    border-radius: 15px;
}

#messageModal .modal-body .text-message.last-message {
    position: relative;
}

#messageModal .modal-body .text-message.last-message::before {
    content: '';
    width: 12px;
    height: 17px;
    position: absolute;
    bottom: 0;
    left: -5px;
    background-image: url(../img/messages/Pointer.png);
    background-repeat: no-repeat;
    background-position: center;
}

#messageModal .modal-body span {
    font-family: 'SF Pro Text', sans-serif;
    font-size: 11px;
    font-weight: 400;
    line-height: 13px;
    letter-spacing: 0px;
    text-align: left;
    color: #858E99;
    margin-bottom: 19px;
    margin-top: 4px;
}

#messageModal .modal-body span.my-message-span {
    text-align: right;
}

#messageModal .modal-body .text-message.my-message.last-message::before {
    bottom: -3px;
    left: unset;
    right: -3px;
    background-image: url(../img/messages/pointer-my.png);
}

#messageModal .modal-body .text-message.my-message {
    justify-content: flex-end;
    color: #fff;
    background-color: #3C3C3C;
}

#messageModal .modal-footer {
    padding: 0 12px 12px;
    border: 0;
}

#messageModal .modal-footer .message-textarea {
    width: 100%;
    padding: 7px 10px;
    border-radius: 8px;
    background-color: #efeff4;
    margin-bottom: 13px;
    resize: none;
    border: 0;
}

#messageModal .modal-footer .message-textarea:focus {
    outline: 0;
    border: 0;
}

#messageModal .modal-footer .send-message {
    padding: 10px 15px;
    background-color: #3C3C3C;
    color: #fff;
    font-family: 'SF Pro Display', sans-serif;
    font-size: 16px;
    font-weight: 500;
    line-height: 22px;
    letter-spacing: 0px;
    text-align: center;
    border-radius: 5px;
    margin: 0;
    box-shadow: none;
    outline: 0;
    border: 0;
}

#messageModal .modal-footer .send-message:hover {
    padding: 10px 15px;
    background-color: #3C3C3C;
    color: #fff;
    font-family: 'SF Pro Display', sans-serif;
    font-size: 16px;
    font-weight: 500;
    line-height: 22px;
    letter-spacing: 0px;
    text-align: center;
    border-radius: 5px;
    margin: 0;
    box-shadow: none;
    outline: 0;
    border: 0;
}

#messageModal .modal-footer .send-message:focus {
    padding: 10px 15px;
    background-color: #3C3C3C;
    color: #fff;
    font-family: 'SF Pro Display', sans-serif;
    font-size: 16px;
    font-weight: 500;
    line-height: 22px;
    letter-spacing: 0px;
    text-align: center;
    border-radius: 5px;
    margin: 0;
    box-shadow: none;
    outline: 0;
    border: 0;
}

/* message end */

/* profile start */
header.header-with-search .profileDropdownMenu {
    width: 230px;
    transform: translate(-200px, 55px) !important;
    background: #fff;
    border-radius: 10px;
    padding: 13px 10px;
    inset: 0px auto auto 0px !important;
    border: 0;
}

header.header-with-search .profile::after {
    content: unset;
}

header.header-with-search .profileDropdownMenu .profile-dropdown-item {
    padding: 6px 10px;
    border-radius: 10px;
    display: flex;
    gap: 12.5px;
    align-items: center;
    margin-bottom: 2px;
    text-decoration: none;
    cursor: pointer;
}

header.header-with-search .profileDropdownMenu .profile-dropdown-item:hover {
    background-color: #F4F4F4;
}

header.header-with-search .profileDropdownMenu .profile-dropdown-item span {
    font-family: 'SF Pro Display', sans-serif;
    font-size: 16px;
    font-weight: 500;
    line-height: 22px;
    letter-spacing: 0px;
    text-align: left;
    color: #3C3C3C;
}

header.header-with-search .profileDropdownMenu .hor-divider {
    border: 1px solid #EFEFF4;
    margin: 12px 0 14px;
}

/* profile end */

/* language and currency start */
#langauage_currency_modal.modal-dialog,
#langauage_currency_modal .modal-dialog {
    max-width: 856px;
    border-radius: 10px;
    border: 0;
}

#langauage_currency_modal .modal-content {
    border: 0;
}

#langauage_currency_modal .modal-header {
    padding: 15px 12px 30px 30px;
    border: 0;
}

#langauage_currency_modal .modal-header .modal-title {
    align-self: flex-start;
    font-family: 'SF Pro Display', sans-serif;
    font-size: 20px;
    font-weight: 700;
    line-height: 22px;
    letter-spacing: 0px;
    text-align: left;
    color: #3C3C3C;
}

#langauage_currency_modal .modal-header .btn-close {
    width: 24px;
    height: 24px;
}

#langauage_currency_modal .modal-body {
    padding: 0 30px 40px;
}

#langauage_currency_modal .modal-body .lang-and-currency-tabs-wrapper button.modal-tab-item,
#langauage_currency_modal .modal-body .lang-and-currency-tabs-wrapper .modal-tab-item button {
    border: 0;
    padding: 0;
    padding-bottom: 20px;
    border-bottom: 2px solid transparent;
    font-family: 'SF Pro Display', sans-serif;
    font-size: 16px;
    font-weight: 500;
    line-height: 22px;
    letter-spacing: 0px;
    text-align: left;
    color: #666666;
}

#langauage_currency_modal .modal-body .lang-and-currency-tabs-wrapper button.modal-tab-item.active,
#langauage_currency_modal .modal-body .lang-and-currency-tabs-wrapper .modal-tab-item button.active {
    border-color: #3C3C3C;
    color: #3C3C3C;
}

#langauage_currency_modal .modal-body .lang-and-currency-tabs-wrapper {
    gap: 20px;
}

#langauage_currency_modal .modal-body .langs-and-regions-wrapper {
    margin-top: 30px;
    row-gap: 20px;
}

#langauage_currency_modal .modal-body .langs-and-regions-wrapper .language-and-region {
    padding: 10px 15px;
    border-radius: 10px;
    border: 1px solid #fff;
    background-color: #fff;
    display: block;
    text-decoration: none;
}

#langauage_currency_modal .modal-body .langs-and-regions-wrapper .language-and-region.active {
    background-color: #fff;
    border-color: #8A8A8F;
}

#langauage_currency_modal .modal-body .langs-and-regions-wrapper .language-and-region:hover {
    background-color: #F4F4F4;
    border-color: #F4F4F4;
}

#langauage_currency_modal .modal-body .langs-and-regions-wrapper .language-and-region h4 {
    font-family: 'SF Pro Display', sans-serif;
    font-size: 16px;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: 0px;
    text-align: left;
    color: #666666;
}

#langauage_currency_modal .modal-body .langs-and-regions-wrapper .language-and-region .language {
    font-weight: 500;
    color: #3C3C3C;
}

/* language and currency end*/

/* logout modal start */

#logout_modal.modal-dialog,
#logout_modal .modal-dialog {
    border: 0;
    border-radius: 10px;
    max-width: 420px;
}

#logout_modal.modal-dialog .modal-header,
#logout_modal .modal-dialog .modal-header {
    padding: 15px 14px 15px 30px;
    border: 0;
}

#logout_modal.modal-dialog .modal-header .modal-title,
#logout_modal .modal-dialog .modal-header .modal-title {
    align-self: flex-start;
    font-family: 'SF Pro Display', sans-serif;
    font-size: 20px;
    font-weight: 700;
    line-height: 22px;
    letter-spacing: 0px;
    text-align: left;
    color: #3C3C3C;
}

#logout_modal.modal-dialog .modal-body,
#logout_modal .modal-dialog .modal-body {
    padding: 0 30px 29px;
    border: 0;
}

#logout_modal.modal-dialog .modal-body p,
#logout_modal .modal-dialog .modal-body p {
    font-family: 'SF Pro Display', sans-serif;
    font-size: 16px;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: 0px;
    text-align: left;
    color: #666;
}

#logout_modal.modal-dialog .modal-footer,
#logout_modal .modal-dialog .modal-footer {
    padding: 0 30px 29px;
    gap: 20px;
    border: 0;
}

#logout_modal.modal-dialog .modal-footer>*,
#logout_modal .modal-dialog .modal-footer>* {
    margin: 0;
}

#logout_modal.modal-dialog .close-btn,
#logout_modal .modal-dialog .close-btn {
    font-family: 'SF Pro Display', sans-serif;
    font-size: 16px;
    font-weight: 500;
    line-height: 19px;
    letter-spacing: 0px;
    text-align: left;
    color: #3C3C3C;
    border: 0;
    padding: 11px 0;
    background-color: transparent;
}

#logout_modal.modal-dialog .logout-btn,
#logout_modal .modal-dialog .logout-btn {
    font-family: 'SF Pro Display', sans-serif;
    font-size: 16px;
    font-weight: 500;
    line-height: 19px;
    letter-spacing: 0px;
    text-align: left;
    color: #EC4E5F;
    padding: 11px 20px;
    border-radius: 5px;
    background-color: #FFEBED;
    border: 0;
}

/* logout modal end */

.offcanvas-backdrop.show {
    opacity: 1;
    background-color: rgba(60, 60, 60, 0.3);
    backdrop-filter: blur(2px);
}

.offcanvas,
.offcanvas-lg,
.offcanvas-md,
.offcanvas-sm,
.offcanvas-xl,
.offcanvas-xxl {
    --bs-offcanvas-width: 280px;
}

.copyright {
    font-family: 'SF Pro Display', sans-serif;
    font-size: 12px;
    font-weight: 500;
    line-height: 14px;
    letter-spacing: 0px;
    text-align: left;
}

.offcanvas-footer .social {
    gap: 16px;
    margin-bottom: 20px;
}


/* vehicle-header */
.vehicle-header {
    padding: 15px 0;
}

.vehicle-header .navbar-collapse {
    flex-basis: unset;
    flex-grow: unset;
}

.vehicle-header .header-left {
    gap: 40px;
}

.vehicle-header .header-left .filters-wrap {
    gap: 15px;
}

.vehicle-header .header-left .filters-wrap .addres-filter {
    gap: 14px;
    display: flex;
    font-family: 'SF Pro Display', sans-serif;
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: 0px;
    text-align: left;
    min-width: 270px;
    color: #3C3C3C;
    align-items: center;
    border: 1px solid #D8D8D8;
    border-radius: 30px;
    padding: 4px 5px 4px 15px;
}

.vehicle-header .header-left .filters-wrap .addres-filter input {
    width: 100%;
    border: 0;
    outline: 0;
    font-family: 'SF Pro Display', sans-serif;
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: 0px;
    text-align: left;
    color: #3C3C3C;
}

.vehicle-header .header-left .filters-wrap .addres-filter input::placeholder {
    font-family: 'SF Pro Display', sans-serif;
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: 0px;
    text-align: left;
    color: #3C3C3C;
}

.vehicle-header .header-left .filters-wrap .addres-filter input:focus {
    outline: 0;
    border: 0;
}

.vehicle-header .header-left .filters-wrap .addres-filter button {
    outline: 0;
    border: 0;
    background-color: transparent;
}

.vehicle-header .header-left .filters-wrap .addres-filter button:focus {
    outline: 0;
    border: 0;
    background-color: transparent;
}

.vehicle-header .header-right {
    gap: 15px;
}