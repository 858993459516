.profile-sidebar {
    padding: 40px 20px 40px 0;
    border-right: 1px solid #D8D8D8;
    /* min-height: calc(100vh - 73px); */
}

.profile-sidebar .accordion-button {
    padding: 0;
    border: 0 !important;
    outline: none !important;
    box-shadow: none !important;
    background-color: transparent !important;
    color: #3C3C3C !important;
    font-family: 'SF Pro Display', sans-serif;
    font-size: 16px;
    font-weight: 700;
    line-height: 22px;
    letter-spacing: 0px;
    text-align: left;

}

.profile-sidebar .accordion-item {
    margin-bottom: 20px;
    padding: 0;
    border: 0 !important;
    outline: none !important;
    box-shadow: none !important;
    background-color: transparent !important;
}

.profile-sidebar .accordion-button::after {
    background-image: url(../img/ArrowTopBig.png);
}

.profile-sidebar .accordion-button:not(.collapsed)::after {
    background-image: url(../img/ArrowTopBig.png);
}

.profile-sidebar .accordion-body {
    padding: 0;
    padding-top: 20px;
}

.profile-sidebar .accordion-body .dropdown-item {
    padding: 6px 10px 6px 0;
    border-radius: 10px;
    display: flex;
    gap: 10px;
    align-items: center;
    font-family: 'SF Pro Display', sans-serif;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0px;
    text-align: left;
    color: #666;
}

.notifications-item {
    padding: 6px 10px 6px 0;
    border-radius: 10px;
    display: flex;
    gap: 10px;
    align-items: center;
    font-family: 'SF Pro Display', sans-serif;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0px;
    text-align: left;
    color: #666;
    text-decoration: none;
    justify-content: space-between;
}

.notifications-item:hover {
    background-color: #F4F4F4;
    color: #666;
}

.profile-sidebar .accordion-body .dropdown-item.active {
    color: #3C3C3C;
}

.profile-sidebar .accordion-body .dropdown-item:hover {
    background-color: #F4F4F4;
}

/* checkbox */
#notifications-nav input {
    display: none;
}

#notifications-nav label {
    width: 44px;
    height: 24px;
    box-sizing: border-box;
    float: left;
    border-radius: 16px;
    position: relative;
    cursor: pointer;
    transition: .3s ease;
    background-color: #D8D8D8;
}

#notifications-nav input[type=checkbox]:checked+label {
    background: #00C48C;
}

#notifications-nav input[type=checkbox]:checked+label:before {
    left: 22px;
}

#notifications-nav label:before {
    transition: .3s ease;
    content: '';
    width: 20px;
    height: 20px;
    position: absolute;
    background: white;
    left: 2px;
    top: 2px;
    box-sizing: border-box;
    border-radius: 100px;
}

#notifications-nav .dropdown-item {
    padding: 0;
    background-color: transparent !important;
    justify-content: space-between;
}

#notifications-nav .accordion-body {
    display: flex;
    flex-direction: column;
    gap: 13px;
}

#notifications-nav .dropdown-item span {
    white-space: initial;
    max-width: 183px;
}

/* pesonal-info */
.author-page-section {
    padding: 40px 0 40px 10px;
    display: none;
}

.author-page-section.active-section {
    display: block;
}

.author-page-section h2 {
    font-family: 'SF Pro Display', sans-serif;
    font-size: 20px;
    font-weight: 700;
    line-height: 22px;
    letter-spacing: 0px;
    text-align: left;
    margin-bottom: 10px;
    color: #3C3C3C;
}

.author-page-section h3 {
    font-family: 'SF Pro Display', sans-serif;
    font-size: 16px;
    font-weight: 500;
    line-height: 22px;
    letter-spacing: 0px;
    text-align: left;
    color: #3C3C3C;
}

.personal-info .user-image-wrapper {
    padding-top: 2px;
    align-items: flex-start;
}

.personal-info .user-image-wrapper img {
    width: 48px;
    height: 48px;
    border-radius: 50%;
}

.info-btn {
    font-family: 'SF Pro Display', sans-serif;
    font-size: 16px;
    font-weight: 400;
    line-height: 19px;
    letter-spacing: 0px;
    text-align: left;
    color: #3C3C3C;
    border: 0;
    outline: 0;
    background-color: transparent;
    border-bottom: 1px solid #3C3C3C;
}

.info-btn.del {
    color: #EC4E5F;
    border-color: #EC4E5F;
}

.personal-info .buttons-wrapper {
    display: flex;
    gap: 20px;
    align-items: inherit;
}

.info-wrapper {
    /* padding: 20px 0; */
    border-bottom: 1px solid #efeff4;
}

.info-wrapper .wrapper-header {
    padding: 20px 0;
}

.align-self-end {
    align-self: flex-end;
}

/* image-editing-view */
.img-btn {
    font-family: 'SF Pro Display', sans-serif;
    font-size: 16px;
    font-weight: 500;
    line-height: 22px;
    letter-spacing: 0px;
    text-align: center;

    padding: 10px 15px;
    border-radius: 5px;
    border: 1px solid #D8D8D8;
    background-color: #fff;
    color: #3C3C3C;
}

.image-editing-view .img-btn.save-btn {
    background-color: #3C3C3C;
    color: #fff;
    border-color: #3C3C3C;
}

.img-btns-wrapper {
    gap: 17px;
}

.image-editing-view-wrapper {
    gap: 15px;
}

.croppie-container .cr-slider-wrap {
    margin: 0 !important;
}

.none-editable:not(.editing-mode) {
    pointer-events: none;
    position: relative;
}

.none-editable:not(.editing-mode)::before {
    content: '';
    width: 100%;
    height: 100%;
    opacity: .7;
    background-color: #fff;
    display: block;
    position: absolute;
    top: 0;
    left: 0;
}



.editing-wrapper label {
    font-family: 'SF Pro Display', sans-serif;
    font-size: 16px;
    font-weight: 500;
    line-height: 22px;
    letter-spacing: 0px;
    text-align: left;
    margin-bottom: 10px;
    color: #3C3C3C;
    display: block;
}

.editing-wrapper .group {
    gap: 20px;
    margin-top: 7px;
}

.editing-wrapper input {
    border: 1px solid #D8D8D8;
    padding: 10px 15px;
    border-radius: 5px;
    font-family: 'SF Pro Display', sans-serif;
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: 0px;
    text-align: left;
}

.editing-wrapper p {
    font-family: 'SF Pro Display', sans-serif;
    font-size: 16px;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: 0px;
    text-align: left;
    color: #666;
    margin-bottom: 20px;
}

.save-btn {
    font-family: 'SF Pro Display', sans-serif;
    font-size: 16px;
    font-weight: 500;
    line-height: 22px;
    letter-spacing: 0px;
    text-align: center;
    background-color: #3C3C3C;
    color: #fff;
    border: 1px solid #3C3C3C;
    padding: 10px 18px;
    border-radius: 5px;
    box-shadow: none;
    outline: none;
}

.editing-wrapper .save-btn {
    margin-top: 20px;
}

.editing-wrapper .ui-button .ui-icon {
    background-image: url(../img/ArrowDownBig.png);
    background-position: center 2px, center;
    /* background-size: contain; */
    background-repeat: no-repeat;
    width: 18px;
    height: 18px;
}

.editing-wrapper #gender-select-button {
    background-color: #fff;
    border: 1px solid #8A8A8F;
    border-radius: 5px;
    padding: 10px 15px;
}

.editing-wrapper .ui-selectmenu-button.ui-button {
    width: 295px;
}

.editing-wrapper #gender-select-button:hover {
    background-color: #fff;
}

.editing-wrapper #gender-select-button:focus {
    background-color: #fff;
}

li.ui-state-disabled.ui-menu-item {
    display: none;
}

.ui-state-active,
.ui-widget-content .ui-state-active,
.ui-widget-header .ui-state-active,
a.ui-button:active,
.ui-button:active,
.ui-button.ui-state-active:hover {
    background-color: #F4F4F4;
    border-color: #F4F4F4;
    color: #3C3C3C;
}

.personal-info .ui-menu .ui-menu-item-wrapper {
    padding: 10px 15px;
    font-family: 'SF Pro Display', sans-serif;
    font-size: 16px;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: 0px;
    text-align: left;
    color: #3C3C3C;
}

#gender-select-menu {
    border: 0;
    border-radius: 5px;
    background-color: #fff;
    box-shadow: 0px 2px 8px 1px #3C3C3C30;
}

.editing-wrapper .group input {
    width: 295px;
}

.wrapper-header p {
    font-family: 'SF Pro Display', sans-serif;
    font-size: 16px;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: 0px;
    text-align: left;
    color: #666;
}

.change-password-wrapper label {
    font-weight: 400;
    color: #666;
    margin-top: 10px;
    display: block;
}

.change-password-wrapper .inp-group:first-child label {
    margin-top: 0px;
}

.mt-30 {
    margin-top: 30px;
}

.mt-50 {
    margin-top: 50px;
}

/* disconnectModal */
#disconnectModal .modal-content {
    border: 0;
    max-width: 400px;
    border-radius: 10px;
}

#disconnectModal .modal-header {
    border: 0;
}

#disconnectModal .modal-footer {
    border: 0;
    padding: 0 30px 40px;
}

#disconnectModal .modal-header .btn-close:focus {
    outline: 0;
    box-shadow: none;
}

#disconnectModal .modal-body {
    padding: 0 30px 26px;
}

#disconnectModal .modal-body h5 {
    font-family: 'SF Pro Display', sans-serif;
    font-size: 20px;
    font-weight: 700;
    line-height: 22px;
    letter-spacing: 0px;
    text-align: left;
    color: #3C3C3C;
}

/* deactivteaccount modal */
#deactivateAccountModal .modal-content {
    border: 0;
    max-width: 400px;
    border-radius: 10px;
}

#deactivateAccountModal .modal-header {
    border: 0;
    padding: 15px 15px 0;
}

#deactivateAccountModal .modal-footer {
    border: 0;
    padding: 0 30px 40px;
}

#deactivateAccountModal .modal-header .btn-close:focus {
    outline: 0;
    box-shadow: none;
}

#deactivateAccountModal .modal-body {
    padding: 0 30px 26px;
}

#deactivateAccountModal .modal-body h5 {
    font-family: 'SF Pro Display', sans-serif;
    font-size: 20px;
    font-weight: 700;
    line-height: 22px;
    letter-spacing: 0px;
    text-align: left;
    color: #3C3C3C;
}

#deactivateAccountModal .modal-body .list {
    padding-top: 16px;
}

#deactivateAccountModal .modal-body .list-item {
    display: flex;
    gap: 10px;
    margin-bottom: 9px;
}

#deactivateAccountModal .modal-body .list-item p {
    font-family: 'SF Pro Display', sans-serif;
    font-size: 16px;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: 0px;
    text-align: left;
    color: #3C3C3C;
}

#deactivateAccountModal .modal-body .list-item span {
    width: 20px;
    height: 20px;
    border: 1.2px solid #D8D8D8;
    border-radius: 50%;
    display: block;
}

.review-payments .info-wrapper .wrapper-header {
    padding: 0 0;
}

.review-payments .info-wrapper {
    border: 0;
}

.author-page-section.review-payments h3 {
    font-size: 20px;
    font-weight: 500;
    line-height: 22px;
    margin-bottom: 5px;
}

#addPaymentMethodModal .modal-content {
    border: 0;
    border-radius: 10px;
    max-width: 400px;
}

#addPaymentMethodModal .modal-header {
    padding: 15px 15px 0 15px;
    border: 0;
}

.modal-dialog-scrollable .modal-body {
    padding: 0 30px 30px;
}

.modal-dialog-scrollable .modal-body h5 {
    font-family: 'SF Pro Display', sans-serif;
    font-size: 20px;
    font-weight: 700;
    line-height: 22px;
    letter-spacing: 0px;
    text-align: left;
    color: #3C3C3C;
    margin-bottom: 30px;
}



.modal-dialog-scrollable .modal-footer {
    padding: 0 30px 30px;
    border: 0;
}

#addPaymentMethodModal .modal-body .option-wrapper {
    gap: 20px;
    margin-bottom: 30px;
}

#addPaymentMethodModal .modal-body .option-group {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
}

#addPaymentMethodModal .modal-body .card-info-group {
    gap: 10px;
    margin-bottom: 20px;
}

#addPaymentMethodModal .modal-body .card-info-group label {
    font-family: 'SF Pro Display', sans-serif;
    font-size: 16px;
    font-weight: 700;
    line-height: 22px;
    letter-spacing: 0px;
    text-align: left;
    color: #3C3C3C;
}

#addPaymentMethodModal .modal-body .card-info-group input {
    padding: 10px 15px;
    border-radius: 5px;
    border: 1px solid #D8D8D8;
    color: #3C3C3C;
    font-family: 'SF Pro Display', sans-serif;
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: 0px;
    text-align: left;
}

#addPaymentMethodModal .modal-body .card-info-group input:focus {
    border: 1px solid #D8D8D8;
    outline: 0;
}

#addPaymentMethodModal .modal-body .card-info-group input::placeholder {
    color: #666;
    font-family: 'SF Pro Display', sans-serif;
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: 0px;
    text-align: left;
}

#addPaymentMethodModal .input-group-wrapper {
    gap: 20px;
}

#addPaymentMethodModal .input-group-wrapper .card-info-group {
    margin-bottom: 0;
}

#addPaymentMethodModal .input-group-wrapper input[name="expiration-date"] {
    width: 215px;
}

#addPaymentMethodModal .input-group-wrapper input[name="cvv"] {
    width: 105px;
}

#addPaymentMethodModal .modal-body .option-group img {
    max-width: 86px;
}

#addPaymentMethodModal .modal-body .option-group .img {
    width: 110px;
    text-align: center;
    border-radius: 5px;
    border: 1px solid #666666;
    padding: 15px 5px;
}

#addPaymentMethodModal .modal-body .option-group input[type="radio"] {
    width: 20px;
    height: 20px;

}

#addPaymentMethodModal .modal-body input[type="radio"] {
    border-radius: 50%;
    width: 16px;
    height: 16px;
    border: 1.2px solid #D8D8D8;
}

#addPaymentMethodModal .modal-body input[type="radio"]:checked {
    border: 2px solid #3C3C3C;
    accent-color: #3C3C3C;
}

#addPaymentMethodModal .invalid {
    border-color: #EC4E5F;
}

.added-cards-wrapper .added-card {
    padding: 30px 0;
    border-bottom: 1px solid #EFEFF4;
}

.added-card .def-btn {
    width: 42px;
    height: 42px;
    border-radius: 5px;
    border: 1px solid #D8D8D8;
    padding: 19px 13px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #F4F4F4;
    gap: 2px;
}

.added-card .def-btn::before {
    display: none;
}

.added-card .def-btn span {
    width: 4px;
    min-width: 4px;
    height: 4px;
    border-radius: 50%;
    background-color: #666;
}

.added-card .dropdown-menu {
    border-radius: 10px;
    border: 0;
}

.added-card .dropdown-menu .def-btn-item {
    padding: 7px 10px;
    border-radius: 10px;
    background: #fff;
    font-family: 'SF Pro Display', sans-serif;
    font-size: 16px;
    font-weight: 500;
    line-height: 22px;
    letter-spacing: 0px;
    text-align: left;
    color: #3C3C3C;
    cursor: pointer;

    border: none;
    outline: none;
}

.added-card .dropdown-menu .def-btn-item:hover {
    background-color: #F4F4F4;
}

.added-card .dropdown-menu .del-card-btn {
    padding: 7px 10px;
    border-radius: 10px;
    background: #fff;
    font-family: 'SF Pro Display', sans-serif;
    font-size: 16px;
    font-weight: 500;
    line-height: 22px;
    letter-spacing: 0px;
    text-align: left;
    cursor: pointer;
    color: #EC4E5F;
    border: none;
    outline: none;
}

.added-card .dropdown-menu .del-card-btn:hover {
    background-color: #F4F4F4;
}

.added-card .card-info-wrapper {
    gap: 10px;
}

.added-card .card-info-wrapper h6 {
    font-family: 'SF Pro Display', sans-serif;
    font-size: 16px;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: 0px;
    text-align: left;
    color: #3C3C3C;
}

.added-card .card-info-wrapper p {
    font-family: 'SF Pro Display', sans-serif;
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: 0px;
    text-align: left;
    color: #666;
}

.added-card .card-info-wrapper .default {
    padding: 5px 10px;
    background-color: #F4F4F4;
    font-family: 'SF Pro Display', sans-serif;
    font-size: 12px;
    font-weight: 500;
    line-height: 14px;
    letter-spacing: 0px;
    text-align: left;
    color: #666;
    text-transform: uppercase;
}

.added-coupon {
    padding: 30px 0;
    border-top: 1px solid #EFEFF4;
    border-bottom: 1px solid #EFEFF4;
    margin: 20px 0;
}

.added-coupon p {
    font-family: 'SF Pro Display', sans-serif;
    font-size: 16px;
    font-weight: 400;
    line-height: 19px;
    letter-spacing: 0px;
    text-align: left;
    color: #3C3C3C;
}

.coupon-add-wrapper input {
    padding: 10px 15px;
    border-radius: 5px;
    border: 1px solid #D8D8D8;
    font-family: 'SF Pro Display', sans-serif;
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: 0px;
    text-align: left;
    color: #3C3C3C;
    margin-bottom: 20px;
    width: 295px;
}

.coupon-add-wrapper input:focus {
    outline: 0;
    border: 1px solid #D8D8D8;
}

.coupon-add-wrapper input::placeholder {
    font-family: 'SF Pro Display', sans-serif;
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: 0px;
    text-align: left;
    color: #666;
}

.coupon-buttons-wrapper {
    display: flex;
    gap: 20px;
}

/* trips section */
.trips-section .trips-tab-wrapper {
    border-color: #EFEFF4;
    margin-top: 30px;
}

.trips-section .trips-tab-wrapper .nav-item .nav-link {
    border: 0;
    font-family: 'SF Pro Display', sans-serif;
    font-size: 16px;
    font-weight: 500;
    line-height: 22px;
    letter-spacing: 0px;
    text-align: left;
    color: #666666;
    padding: 0 20px 20px 0;
}

.trips-section .trips-tab-wrapper .nav-item .nav-link.active {
    border-bottom: 2px solid #3C3C3C;
    color: #3C3C3C;
}

.trips-section .tab-content {
    padding-top: 28px;
}

.trips-section .tab-content .trips-item {
    border-radius: 10px;
    border: 1px solid #EFEFF4;
    margin-bottom: 30px;
    border-radius: 10px;
    overflow: hidden;

}

.trips-section .tab-content .trips-item .vehicle-img img {
    max-width: 228px;
    border-radius: 5px;
}

.trips-section .tab-content .trips-item .vehicle-name-wrapper {
    gap: 20px;
    align-items: center;
}

.trips-section .tab-content .trips-item .vehicle-name-wrapper h2 {
    margin-bottom: 0;
    color: var(--black, #3C3C3C);
    font-family: 'SF Pro Display', sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 22px;
    /* 137.5% */
}

.trips-section .tab-content .trips-item .status {
    padding: 2px 20px;
    border-radius: 30px;
    background-color: rgba(0, 196, 140, 0.12);
    border: 1px solid #00C48C;
    font-family: 'SF Pro Display', sans-serif;
    font-size: 14px;
    font-weight: 500;
    line-height: 22px;
    letter-spacing: 0px;
    text-align: left;
    color: #3C3C3C;
    text-transform: capitalize;
}

.trips-section .tab-content .trips-item .status.yellow {
    background-color: rgba(248, 204, 13, 0.12);
    border: 1px solid #F8CC0D;
}

.trips-section .tab-content .trips-item .status.blue {
    background-color: rgba(0, 122, 255, 0.12);
    border: 1px solid #007AFF;
}

.trips-section .tab-content .trips-item .status.purple {
    background-color: rgba(121, 63, 223, 0.12);
    border: 1px solid #793FDF;
}

.trips-section .tab-content .trips-item .status.red {
    background-color: rgba(236, 78, 95, 0.12);
    border: 1px solid #EC4E5F;
}

.trips-section .tab-content .trips-item .order-number {
    font-family: 'SF Pro Display', sans-serif;
    color: var(--Secondary, #666);
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 22px;
    margin: 0;
    padding: 0;
    border: 0;
    text-align: right;
}

.trips-section .tab-content .trips-item .item-name {
    font-family: 'SF Pro Display', sans-serif;
    color: var(--Secondary, #666);
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 22px;
    margin: 0;
    padding: 0;
    border: 0;
    text-align: right;
    margin-top: 5px;
}

.trips-section .tab-content .trips-item .rate {
    gap: 5px;
}

.trips-section .tab-content .trips-item .rate span {
    font-family: 'SF Pro Display', sans-serif;
    font-size: 16px;
    font-weight: 500;
    line-height: 22px;
    letter-spacing: 0px;
    text-align: left;
    color: #3C3C3C;
}

.trips-section .tab-content .trips-item .trip-date-wrapper {
    gap: 11px;
}

.trips-section .tab-content .trips-item .trip-date-wrapper h4,
.trips-section .tab-content .trips-item .trip-price-wrapper h4 {
    font-family: 'SF Pro Display', sans-serif;
    color: var(--black, #3C3C3C);
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 22px;
    margin-bottom: 3px;
}

.trips-section .tab-content .trips-item .trip-price-wrapper h4 {
    text-transform: uppercase;
}

.trips-section .tab-content .trips-item .trip-date-wrapper h5,
.trips-section .tab-content .trips-item .trip-price-wrapper h5 {
    color: var(--Secondary, #666);
    font-family: 'SF Pro Display', sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 22px;
    margin: 0;
    padding: 0;
    border: 0;
}


.trips-section .tab-content .trips-item .trip-date-wrapper .divider {
    width: 8px;
    height: 2px;
    display: block;
    background-color: #3C3C3C;
    margin-top: 10px;
}

.trips-section .tab-content .trips-item .trip-info-wrapper {
    gap: 20px;
}

.trips-section .tab-content .trips-item .left-block {
    gap: 77px;
}

.trips-section .tab-content .trips-item .right-block {
    justify-content: space-between;
    flex-grow: 1;
}

.trips-section .tab-content .trips-item .item-header {
    padding: 15px 30px;
    background-color: #F4F4F4;
    cursor: pointer;
    gap: 30px;
}

.trips-section .tab-content .trips-item .item-body {
    padding: 15px 30px;
}

.trips-section .tab-content .trips-item .item-body .item-btn {
    color: var(--White, #FFF);
    font-family: 'SF Pro Display', sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 22px;
    border-radius: 5px;
    padding: 10px 15px;
    background: var(--black, #3C3C3C);
    border: 0;
    outline: 0;
}