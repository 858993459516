.gm-style .gm-style-iw-tc::after,
.gm-style-iw.gm-style-iw-c {
    background: #FADA1C;
    padding: 0;
}
/* .gm-style-iw.gm-style-iw-c button{
    display: none !important;
} */
.marker-popup {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
    padding: 20px;
}

.gm-style .gm-style-iw-tc {
    height: 12px;
    left: -95px;
    position: absolute;
    top: -100px;
}

.gm-style .gm-style-iw-tc::after {
    transform: rotate(90deg);
}

.marker-popup .title {
    font-family: 'SF Pro Display', sans-serif;
    font-size: 14px;
    font-weight: 500;
    line-height: 22px;
    letter-spacing: 0px;
    text-align: left;
    color: #3C3C3C;
    margin-top: 0;
    padding: 0;
    border: unset;
}

.marker-popup .description {
    font-family: 'SF Pro Display', sans-serif;
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: 0px;
    text-align: left;
    color: #fff;
    letter-spacing: 0.05em;
}

.marker-popup .select-btn {
    background-color: #fff;
    padding: 9px 18px;
    border-radius: 30px;
    font-family: 'SF Pro Display',sans-serif;
    font-size: 14px;
    font-weight: 700;
    line-height: 22px;
    letter-spacing: 0px;
    text-align: left;
    color: #3C3C3C;
    border: none;
    text-decoration: none;
}
.gm-style-iw-d{
    overflow: auto !important;
}
.homepage-map-section {
    width: 50%;
}

.homepage-map-section .rent-footer {
    background-color: #F8CC0D;
}
#map {
    width: 100%;
    height: 100%;
}

.custom-marker {
    width: 40px;
    height: 40px;
    background-image: url(../img/marker.png);
    background-size: contain;
    background-repeat: no-repeat;
}