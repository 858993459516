.privacy-policy-page-header {
    padding: 105px 0;
    background-image: url(../img/pexels-supreet-78397301.png);
    background-repeat: no-repeat;
    background-size: 100%;
}

input:focus {
    outline: none !important;
    box-shadow: none !important;
}

textarea:focus {
    outline: none !important;
    box-shadow: none !important;
}

.your-car-email {
    margin-bottom: 10px;
}

section h5 {
    font-family: 'SF Pro Display';
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 22px;
    color: #3C3C3C;
    margin-top: 40px;
    border-bottom: 1px solid #D8D8D8;
    padding-bottom: 20px;
}

.closed-dates-paragraph {
    color: var(--black, #3C3C3C);
    font-family: 'SF Pro Display', sans-serif;
    font-size: 13.9px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
}

.accordion-button:not(.collapsed)::before {
    background-image: var(--bs-accordion-btn-active-icon);
    transform: var(--bs-accordion-btn-icon-transform);
}

.accordion-button::before {
    flex-shrink: 0;
    width: var(--bs-accordion-btn-icon-width);
    height: var(--bs-accordion-btn-icon-width);
    margin-right: 20px;
    content: "";
    background-image: var(--bs-accordion-btn-icon);
    background-repeat: no-repeat;
    background-size: var(--bs-accordion-btn-icon-width);
    transition: var(--bs-accordion-btn-icon-transition);
}

.accordion-button::after {
    display: none;
}

.accordion-button {
    padding-left: 0px;
}

.accordion-body {
    padding: 0px;
}

.accordion-body input {
    border: 1px solid #D8D8D8 !important;
    border-radius: 5px;
    padding: 8px 15px;
}

.accordion-body .input-no-border input {
    border: unset !important;
    border-radius: unset;
    padding: 0;
}

.accordion-body h6 {
    margin-bottom: 10px;
    margin-top: 20px;
    font-weight: 700;
}

.your-car-email {
    width: 100%;
    display: block;
}

.your-vin {
    width: 295px;
    display: block;
}

a.about-vin {
    display: block;
    color: #666666;
    margin-top: 10px;
}

.car-year {
    padding: 7px 15px;
    -moz-appearance: none;
    /* Firefox */
    -webkit-appearance: none;
    /* Safari and Chrome */
    appearance: none;

}

/*.car-year:after {*/
/*    content: '\f078';*/
/*    font: normal normal normal 17px/1 FontAwesome;*/
/*    color: #0ebeff;*/
/*    right: 11px;*/
/*    top: 6px;*/
/*    height: 34px;*/
/*    padding: 15px 0px 0px 8px;*/
/*    border-left: 1px solid #0ebeff;*/
/*    position: absolute;*/
/*    pointer-events: none;*/
/*}*/

/*.your-car .ui-button .ui-icon,*/
/*.country-code .ui-button .ui-icon,*/
/*.driver-license .ui-button .ui-icon,*/
/*.date-birth .ui-button .ui-icon,*/
/*.accordion-body .ui-button .ui-icon{*/
/*    background-image: url(../img/ArrowDownBig.png) !important;*/
/*    background-position: center 2px, center;*/
/*    background-size: contain;*/
/*    background-repeat: no-repeat;*/
/*    width: 18px;*/
/*    height: 18px;*/
/*}*/
.list-car-section .ui-button,
.select-lg,
.your-car #car-year-button,
#car-year1-button,
#car-year2-button,
#car-year3-button,
#car-year4-button,
#car-year5-button,
#car-year6-button,
#country-code-button,
#issuing-country-button,
#date-year-button,
#date-month-button,
#date-day-button,
#car-number-button,
#car-state-button {
    background-color: #fff;
    border: 1px solid #8A8A8F;
    border-radius: 5px;
    padding: 10px 15px;
}

#country-code-button,
#issuing-country-button {
    width: 295px !important;
}


.your-car #car-year-button:hover {
    background-color: #fff;
    border: 1px solid #8A8A8F;
    border-radius: 5px;
    padding: 10px 15px;
}

.list-car-section .ui-selectmenu-button.ui-button {
    width: 100% !important;
}

.transmission {
    margin-top: 20px;
}

.transmission label {
    margin-left: 14px;
    margin-right: 25px;
}

.listing-personal {
    margin-top: 20px;
}

.listing-personal h6 {
    font-size: 14px;
    margin: 0;
}

.listing-personal p {
    font-size: 12px;
}

.list-car-section p {
    color: #666666 !important;
}

.list-car-section h6 {
    color: #3C3C3C !important;
}

.next-but {
    margin-top: 30px;
    display: inline-block;
    background-color: #3C3C3C;
    color: #fff;
    border-radius: 5px;
    padding: 10px 28px;
    text-decoration: none;
    margin-bottom: 30px;
}

.next-but:hover {
    color: #fff !important;
}

.phone-number {
    width: 295px;
}

.license-number input {
    width: 295px;
}

.name-sec input {
    width: 190px;
    background: #FFFFFF;
    border: 1px solid #D8D8D8;
    border-radius: 5px;
}

.name-sec h6,
.date-birth h6 {
    color: #3C3C3C;
    font-family: 'SF Pro Display';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
}

p,
h6 {
    font-family: 'SF Pro Display';
    font-style: normal;
}

.name-p {
    font-size: 14px;
    margin-top: 10px;
}

.accordion-body textarea {
    padding: 10px 15px;
    border: 1px solid #D8D8D8;
    border-radius: 5px;
    width: 100%;
    resize: none;
}

.checkbox-div label {
    font-family: 'SF Pro Display';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    color: #3C3C3C;
    /*margin-bottom: 12px;*/
    display: flex;
}

.checkbox-div {
    margin-top: 20px;
}

.checkbox-div .row {
    row-gap: 10px;
}

.checkbox-div input {
    margin-right: 10px;
}

.custom-file-input {
    color: transparent;
}

.custom-file-input::-webkit-file-upload-button {
    visibility: hidden;
}

.custom-file-input::before {
    content: 'Add photos';
    color: black;
    display: inline-block;
    border: 1px solid #3C3C3C;
    background: #3C3C3C;
    border-radius: 5px;
    padding: 10px 20px;
    outline: none;
    white-space: nowrap;
    -webkit-user-select: none;
    cursor: pointer;
    color: white;
    font-weight: 500;
    font-size: 16px;
}

.custom-file-input:active {
    outline: 0;
}

.custom-file-input:active::before {
    background: -webkit-linear-gradient(top, #e3e3e3, #f9f9f9);
}

.car-photo input {
    border: none !important;
    padding: 0;
    width: 120px;
    margin-top: 20px;
    margin-bottom: 30px;
}

.car-photo {
    margin-bottom: 10px;
}

.car-photo img {
    margin-bottom: 20px;
}

.photo-wrapper {
    position: relative;
}

.photo-wrapper .check {
    position: absolute;
    top: -10px;
    right: 0;
    width: 32px;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    border: unset;
    font-size: 25px;
    color: white;
    background: rgb(25, 135, 84);
    cursor: help;
    user-select: none;
}

.photo-wrapper .remove {
    position: absolute;
    top: -10px;
    right: 0;
    width: 32px;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    border: unset;
    font-size: 24px;
    line-height: 24px;
    color: white;
    background: #EC4E5F;
}

.photo-wrapper img {
    cursor: pointer;
}

.accordion-button:not(.collapsed) {
    color: #3C3C3C;
    background-color: #fff;
}

.accordion-button:not(.collapsed)::before {
    background-image: var(--bs-accordion-btn-icon);
    ;
}

.accordion-button:focus {
    box-shadow: none;
    border-color: #ffff;
}

.accordion-button::after {
    content: 'Add';
    font-size: 14px;
    color: #3C3C3C;
}

.agree-button {
    display: inline-block;
    margin-top: 20px;
    padding: 10px 20px;
    font-family: 'SF Pro Display';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 22px;
    color: #3C3C3C;
    text-decoration: none;
    border: 1px solid #D8D8D8;
    border-radius: 5px;
    margin-bottom: 30px;
    cursor: pointer;
}

.agree-button.active {
    background-color: #3C3C3C;
    color: #fff;
}

.publish-div {
    border-top: 1px solid #D8D8D8;
    display: flex;
    align-items: center;
    gap: 10px;
}

.publish-but {
    margin-top: 30px;
    display: inline-block;
    background-color: #3C3C3C;
    color: #fff;
    border-radius: 5px;
    padding: 10px 28px;
    text-decoration: none;
    margin-bottom: 30px;
}

.publish-but:hover {
    color: #fff;
}

.accordion-button {
    padding: 20px 0;
}

.dropdown-but b,
.dropdown-but a {

    color: #3C3C3C;
}

.dropdown-but {
    width: 100%;
}


.checkbox-div input[type="checkbox"] {
    appearance: none;
    width: 16px;
    height: 16px;
    outline: 2px solid #D8D8D8;
    outline-offset: 2px;
    border-radius: 10px;
    border: 2px solid #D8D8D8;
    padding: unset;
    border: unset !important;
}

.checkbox-div input[type="checkbox"]:checked {
    background: #3C3C3C;
    outline: 2px solid #3C3C3C;
    border-radius: 50%;
    /*border: 2px solid #3C3C3C;*/
}

label,
p,
input,
h6,
button,
a,
span {
    font-family: 'SF Pro Display';
    font-style: normal;
}

.list-car-section .ui-selectmenu-button-open .ui-icon {
    background-image: url(../img/ArrowTopBig.png) !important;
    background-position: center 2px, center;
    background-repeat: no-repeat;
}

.list-car-section .ui-selectmenu-button-closed .ui-icon {
    background-image: url(../img/ArrowDownBig.png) !important;
    background-position: center 2px, center;
    background-repeat: no-repeat;
}

.list-car-section .ui-button {
    border-color: #D8D8D8;
    background-color: transparent;
    border-radius: 5px;
}

.list-car-section .ui-button:hover,
.list-car-section .ui-button:focus {
    color: #666666 !important;
    background-color: transparent !important;
    border-color: #D8D8D8;
}


.list-car-section .input-group-text {
    color: #666666 !important;
    background-color: #F4F4F4 !important;
}

.list-car-section .ui-selectmenu-text {
    color: #666666 !important;
}

.list-car-section .ui-selectmenu-menu {
    background-color: transparent;
}

.list-car-section .ui-state-active,
.list-car-section .ui-widget-content .ui-state-active,
.list-car-section .ui-widget-header .ui-state-active,
.list-car-section a.ui-button:active,
.list-car-section .ui-button:active,
.list-car-section .ui-button.ui-state-active:hover {
    background: #F4F4F4;
    border: 0;
    border-radius: 5px;
    color: #3C3C3C;
    margin: 0 !important;
}

.list-car-section .ui-widget.ui-widget-content {
    border-radius: 5px;
    background-color: #fff !important;
    border: 0;
}

.list-car-section .ui-menu-item {
    background: transparent;
    border: 0;
    border-radius: 5px;
    color: #3C3C3C;
    padding: 7px;
}


.round {
    position: relative;
    display: flex;
    align-items: center;
}

.round span {
    color: #3C3C3C !important;
    font-size: 14px;
    line-height: 22px;
}

.round label {
    background-color: #fff;
    border: 1.2px solid #D8D8D8;
    border-radius: 50%;
    cursor: pointer;
    height: 20px;
    left: 0;
    position: absolute;
    top: 0;
    width: 20px;
}

.round label:after {
    content: "";
    left: 50%;
    opacity: 0;
    position: absolute;
    top: 50%;
    width: 11px;
    height: 11px;
    border-radius: 50%;
    background: #3C3C3C;
    transform: translate(-50%, -50%);
}

.round input[type="checkbox"] {
    visibility: hidden;
}

.round input[type="checkbox"]:checked+label {
    background-color: #fff;
    border: 2px solid #3C3C3C;
}

.round input[type="checkbox"]:checked+label:after {
    opacity: 1;
}

input[type="radio"] {
    border-radius: 50%;
    width: 20px;
    height: 20px;
    border: 1.2px solid #D8D8D8;
}

input[type="radio"]:checked {
    border: 2px solid #3C3C3C;
    accent-color: #3C3C3C;
}

.accordion-button:not(.collapsed) .accordion-add-btn {
    display: none;
}

.accordion-item[active-item="false"] {
    opacity: 0.5;
    pointer-events: none;
}

select.invalid+.ui-selectmenu-button,
textarea.invalid,
input.invalid {
    border-color: #EC4E5F !important;
    accent-color: #EC4E5F !important;
}