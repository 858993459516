/* footer */

footer {
    padding: 40px 0 20px;
    border-top: 1px solid #EFEFF4;
    font-family: 'SF Pro Display', sans-serif;
    font-size: 16px;
    font-weight: 400;
    line-height: 19px;
    letter-spacing: 0px;
    text-align: left;
    color: #3C3C3C;
}

.footer-wrapper {
    gap: 80px;
}

footer .footer-brand {
    display: block;
    margin-bottom: 20px;
}

footer .footer-left-col {
    flex-basis: 210px;
}

footer .footer-description {
    margin-bottom: 20px;
}

footer .social-wrapper {
    gap: 13px;
}

footer .footer-right-col {
    margin-top: 12px;
    display: flex;
    gap: 60px;
    justify-content: space-between;
}

footer .menu-footer {
    list-style-type: none;
}

footer .menu-item a {
    display: block;
    text-decoration: none;
    color: #3C3C3C;
    margin-bottom: 10px;
}

footer .menu-item.menu-title a,
footer .menu-item.menu-title span {
    display: block;
    font-weight: 700;
    margin-bottom: 20px;
}

footer .menu-title a {
    text-decoration: none;
    color: #3C3C3C;
    display: block;
    font-weight: 700;
    margin-bottom: 20px;
}

footer .download-app-column {
    flex-basis: 32%;
}

footer .download-app-column .description {
    margin-bottom: 20px;
    max-width: 265px;
}

footer .download-app-column .app-links-wrapper {
    gap: 15px;
}

footer .subscribe-col {
    flex-basis: 20%;
}

footer .subscribe-col .description {
    margin-bottom: 20px;
    max-width: 215px;
}

footer .subscribe-col .subscribe-wrapper {
    display: flex;
    padding: 5px;
    border: 1px solid #D8D8D8;
    border-radius: 30px;
}

footer .subscribe-col .subscribe-wrapper .subscribe-input {
    border: 0;
    outline: 0;
    padding: 5px 15px;
    background: transparent;
    border-radius: 30px;
    max-width: 155px;
}

.btn-subscribe {
    font-size: 14px;
    font-weight: 500;
    line-height: 17px;
    letter-spacing: 0px;
    text-align: left;
    color: #fff;
    background-color: #F8CC0D;
    border-radius: 30px;
    padding: 8px 16px;
}

.btn-subscribe:hover {
    color: #fff;
    background-color: #F8CC0D;
}

footer .copyright,
footer .made_by {
    color: #666;
    font-size: 14px;
    font-family: 'SF Pro Display', sans-serif;
    font-weight: 400;
}