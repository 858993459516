.contact{
    padding:40px 0;
}
.contact-menu-in-header {
    padding-top: 20px;
    padding-bottom: 20px;
    display: flex;
    align-items: center;
}

.contact-menu-logo {
    margin-right: 40px;
}

.contact-search-location {
    display: flex;
    align-items: center;
    padding-left: 15px;
    padding-right: 51px;
    background: #FFFFFF;
    border: 1px solid #D8D8D8;
    border-radius: 30px;
    padding-top: 8px;
    padding-bottom: 8px;
    margin-right: 15px;
}

.contact-search-location-text {
    font-family: 'SF Pro Display';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    color: #3C3C3C;
    padding-left: 10px;
}

.contact-data-text {
    font-family: 'SF Pro Display';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    color: #3C3C3C;
}

.contact-data{
    display: flex;
    align-items: center;
    border-right:  1px solid #EFEFF4;
    gap: 10px;
    padding-right: 15px;
}

.contact-time-text {
    font-family: 'SF Pro Display';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    color: #3C3C3C;
}

.contact-search-data {
    display: flex;
    align-items: center;
    background: #FFFFFF;
    border: 1px solid #D8D8D8;
    border-radius: 30px;
    padding-left: 15px;
    padding-right: 15px;
    padding-top: 8px;
    padding-bottom: 8px;
    margin-right: 15px;
}

.contact-time {
    display: flex;
    align-items: center;
    gap: 10px;
    padding-left: 15px;
}

.contact-menu {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.button-icons {
    display: flex;
    gap: 15px;
}

.contact-header {
    border-bottom: 1px solid #EFEFF4;
}

.contact-main-h1 h1{
    text-align: center;
    width: 35%;
    font-family: 'SF Pro Display';
    font-style: normal;
    font-weight: 900;
    font-size: 42px;
    line-height: 46px;
    color: #3C3C3C;
    margin: 0 auto;
    margin-bottom: 40px;
}

.contact-main-content-text p {
    font-family: 'SF Pro Display';
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    color: #3C3C3C;
    text-align: center;
}

.help-center-section-border {
    background-color: #EFEFF4;
    height: 1px;
    margin: 0 auto;
    margin-top: 7px;
    margin-bottom: 13.5px;
}

.help-center-section-content {
    padding-left: 20px;
    padding-right: 20px;
}

.contact-city {
    box-shadow: 0px 4px 8px rgba(60, 60, 60, 0.06), 0px 4px 4px rgba(60, 60, 60, 0.06);
    border-radius: 10px;
}

.center-city-name {
    font-family: 'SF Pro Display';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 22px;
    color: #3C3C3C;
    padding-top: 23px;
}

.help-center-mail {
    font-family: 'SF Pro Display';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    color: #3C3C3C;
    display: block;
    text-decoration: none;
}

.help-center-number {
    font-family: 'SF Pro Display';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    color: #3C3C3C;
    padding-bottom: 18.5px;
    display: block;
    text-decoration: none;
}

.contact-city img {
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
}

.help-center-contacts {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-left: 40px;
    padding-right: 40px;
    gap: 100px;
}

.wrapper {
    padding-bottom: 150px;
}

.contact-main-text-content {
    margin-bottom: 72px;
}
