.privacy-policy-page-header{
    padding: 105px 0;
    background-image: url(../img/terms-and-privacy/pexels-supreet-78397301.png);
    background-repeat: no-repeat;
    background-size: 100%;
}
.privacy-chapters{
    margin-top: 40px;
}
.privacy-chapters h3{

    font-family: 'SF Pro Display';
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 22px;
    color: #3C3C3C;
}

.privacy-chapters a,
.privacy-chapters .table-of-content{
    display: block;
    color: #3C3C3C;
    padding: 20px 0;
    border-bottom: 1px solid #EFEFF4;
    font-family: 'SF Pro Display', sans-serif;
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    text-decoration: underline;
    cursor: pointer;
}

.privacy-policy-text{
    margin-top: 5px;
}

.privacy-policy-text h4{
    margin-top: 45px;
    font-family: 'SF Pro Display';
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 22px;
    color: #3C3C3C;
}

.privacy-policy-text p{
    margin-top: 15px;
    font-family: 'SF Pro Display';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    color: #3C3C3C;
}

.privacy-policy-text h5{
    font-family: 'SF Pro Display';
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 22px;
    color: #3C3C3C;
    margin-top: 40px;
}