section.create-car-banner {
    background-image: url(../img/pexels-car-88914141.png);
    padding: 270px 0;
    margin: 0 auto;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    height: calc(100vh - 73px);
}

section.create-car-banner h2 {
    font-family: 'SF Pro Display';
    font-style: normal;
    font-size: 42px;
    font-weight: 700;
    line-height: 46px;
    margin-bottom: 20px;
}

section.create-car-banner p {
    font-size: 20px;
    line-height: 24px;
    width: 65%;
    margin: 0 auto;
    margin-bottom: 31px;
}

section.create-car-banner a {
    text-decoration: none;
    color: #3C3C3C;
    background-color: #ffffff;
    padding: 7px 65px;
    border: 1px solid #D8D8D8;
    border-radius: 30px;
    font-size: 14px;
    line-height: 22px;
}