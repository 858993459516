.login-section {
    background: #F9F9F9;
}

.login-card {
    width: 345px;
}

.login-card .login-title {
    color: #000;
    text-align: center;
    font-family: 'SF Pro Display', sans-serif;
    font-size: 28px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin-bottom: 9px;
}

.login-card .login-subtitle {
    text-align: center;
    color: #666;
    text-align: center;
    font-family: 'SF Pro Text', sans-serif;
    font-size: 17px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
}

.login-card .login-form {
    margin-top: 32px;
    border-radius: 8px;
    border: 1px solid #EFEFF4;
    background: #FFF;
    padding: 32px 16px;
}

.login-card .login-form .login-input {
    border-radius: 8px;
    background-color: #EFEFF4;
    color: #000;
    font-family: 'SF Pro Text', sans-serif;
    font-size: 17px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
    padding: 11px 16px;
    margin-bottom: 8px;
    border: 1px solid #fff;
}

.login-card .login-form .login-input.is-invalid {
    border-color: #dc3545;
    background-repeat: no-repeat;
    background-position: right calc(0.375em + 0.1875rem) center;
    background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}

.custom-check {
    width: 20px;
    height: 20px;
    border-radius: 10px;
    border: 1px solid #D8D8D8;
    background-color: #FFFFFF;
    display: inline-block;
}

.custom-check.active {
    background-color: #F8CC0D;
    border: 1px solid #F8CC0D;
    display: flex;
    align-items: center;
    justify-content: center;
}

.custom-check.active::before {
    content: '';
    border-left: 2px solid white;
    border-bottom: 2px solid white;
    width: 12px;
    height: 6px;
    transform-origin: center;
    transform: rotate(-45deg) translate(1px, -1px);
}

.login-card .login-button {
    border-radius: 8px;
    background: #F8CC0D;
    padding: 14px 16px;
    color: #FFF;
    text-align: center;
    font-family: 'SF Pro Text', sans-serif;
    font-size: 17px;
    font-style: normal;
    font-weight: 600;
    line-height: 22px;
}

.signin-signup-form-footer {
    text-align: center;
    margin-top: 45px;
    color: #666;
    text-align: center;
    font-family: 'SF Pro Text', sans-serif;
    font-size: 17px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
    user-select: none;
}

.signin-signup-form-footer a {
    color: #007AFF;
    font-family: 'SF Pro Text', sans-serif;
    font-size: 17px;
    font-style: normal;
    font-weight: 700;
    line-height: 22px;
    text-decoration: none;
}

.role-toggler {
    border-radius: 14.5px;
    background: #FFF;
    box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.10);
    padding: 2px;
    display: flex;
    justify-content: space-between;
}

.role-toggler label {
    border-radius: 14.5px;
    padding: 4px 12px;
    color: #8A8A8F;
    text-align: center;
    font-family: 'SF Pro Text', sans-serif;
    font-size: 13px;
    font-style: normal;
    font-weight: 600;
    line-height: 18px;
    flex-grow: 1;
    cursor: pointer;
    user-select: none;
}

.role-toggler label.active {
    color: #FFF;
    background: #F8CC0D;
}