@media screen and (max-width: 1400px) {
    .vehicle-header .header-left .filters-wrap .filter .date {
        padding: 6px;
    }

    .vehicle-single .sidebar-widget .hasDatepicker {
        width: 150px;
    }
}

@media screen and (max-width:1200px) {
    .vehicle-single > .container > .row {
        flex-direction: column;
    }

    .vehicle-single > .container > .row > .col-md-4 {
        width: 100%;
    }

}


@media screen and (max-width:991px) {
    .vehicle-single > .container > .row > .col-md-8 {
        width: 73%;
    }


}


.profileDropdownMenu.dropdown-menu.show, .notificationsDropdownMenu.dropdown-menu.show, .Hub .Panel.items-panel, .Inbox:not(.chat-only) .ChatBox .message-panel, .ui-widget.ui-widget-content, .sort-filter-dropdown .dropdown-menu.show, .offcanvas.offcanvas-end.show, .modal-dialog-scrollable .modal-content, .Toastify__toast-theme--colored.Toastify__toast--success, .offcanvas.hiding, .offcanvas.show, .offcanvas.showing{
    box-shadow: 0px 4px 4px 0px rgba(60, 60, 60, 0.06), 0px 4px 8px 0px rgba(60, 60, 60, 0.06);
}


@media screen and (max-width: 990px) {
    .editing-wrapper > .group.d-flex {
        flex-direction: column;
    }
}
