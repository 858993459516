.vehicle-single {
    padding: 40px 0 0;
}

.vehicle-single .sidebar-widget {
    padding: 40px 30px;
    border-radius: 10px;
    box-shadow: 0px 3px 15px 10px #3C3C3C0F;
}

.vehicle-single .sidebar-widget h2.price {
    font-family: 'SF Pro Display', sans-serif;
    font-size: 24px;
    font-weight: 700;
    line-height: 22px;
    letter-spacing: 0px;
    text-align: left;
    color: #3C3C3C;
    margin-bottom: 30px;
}

.userContacts {
    padding-bottom: 17px;
}

.userContacts .userName {
    background: #F8CC0D;
    color: #FFF;
    font-family: 'SF Pro Display', sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 22px;
    text-transform: uppercase;
    padding: 10px 30px;
}

.userContacts .singleContact {
    display: flex;
    gap: 15px;
    padding: 20px 30px;
    position: relative;
}

.userContacts .singleContact::before {
    content: "";
    width: 10px;
    height: 10px;
    position: absolute;
    top: 20px;
    left: 10px;
    border-radius: 5px;
    background: #4CD964;
}

.userContacts .singleContact.offline::before {
    background: rgba(246, 30, 30, 0.80);
}

.userContacts .singleContact:not(:last-child) {
    border-bottom: 1px solid #EFEFF4;
}

.userContacts .singleContact img {
    width: 68px;
    height: 68px;
}

.userContacts .singleContact .singleContactData {
    flex-grow: 1;
}

.userContacts .singleContact .singleContactData b {
    color: #3C3C3C;
    font-family: 'SF Pro Display', sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 22px;
}

.userContacts .singleContact .singleContactData a {
    color: #666;
    text-align: right;
    font-family: 'SF Pro Display', sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
    /* 157.143% */
    text-decoration-line: underline;
}

.userContacts .singleContact .singleContactData .userContactValue {
    color: #666;
    font-family: 'SF Pro Display', sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.sidebar-filters {
    gap: 25px;
}

.sidebar-filters .filter-title {
    font-family: 'SF Pro Display', sans-serif;
    font-size: 16px;
    font-weight: 700;
    line-height: 22px;
    letter-spacing: 0px;
    text-align: left;
    color: #3C3C3C;
    margin-bottom: 10px;
}

.sidebar-filters .date-input-group {
    padding: 9px 15px;
    border-radius: 5px;
    border: 1px solid #D8D8D8;
    gap: 15px;
    gap: 0;
    position: relative;
    z-index: 100;
}

.sidebar-filters .date-input-group .divider {
    width: 1px;
    min-width: 1px;
    height: 22px;
    background-color: #efeff4;
}

.sidebar-filters .date-input-group>.ui-front {
    padding-left: 14px;
}

.vehicle-single .sidebar-widget .hasDatepicker {
    width: 192px;
    transform: translateY(2px);
    padding-bottom: 0;
    border: 0;
    padding-left: 49px;
    background-color: transparent;

}

.vehicle-single .sidebar-widget .date {
    position: relative;
    margin-left: -15px;
}

.vehicle-single .sidebar-widget .date::after {
    content: '';
    margin-left: 13px;
    display: inline-flex;
    width: 18px;
    height: 18px;
    align-self: center;
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    background-image: url(../img/ArrowDownBig.png);
    position: absolute;
    top: 50%;
    right: 0;
    transform: translateY(-10px);
}

.vehicle-single .sidebar-widget .date.closed::after {
    background-image: url(../img/ArrowDownBig.png) !important;
}

.vehicle-single .sidebar-widget .date.opened::after {
    background-image: url(../img/ArrowTopBig.png) !important;
}

.vehicle-single .sidebar-widget .date .icon {
    position: absolute;
    top: 0;
    left: 15px;
}

.vehicle-single .sidebar-widget .ui-selectmenu-button.ui-button {
    width: unset;
    padding: 0;
    border-radius: 0;
    background-color: transparent;
    border: 0;
}

.vehicle-single .sidebar-widget .ui-selectmenu-menu li.ui-menu-item {
    border-radius: 5px;
    padding: 4px;
}

.vehicle-single .sidebar-widget .ui-selectmenu-menu li.ui-menu-item:hover {
    background-color: #F4F4F4;
}

.vehicle-single .sidebar-widget .ui-selectmenu-button.ui-button {
    width: unset;
    padding: 0;
    border-radius: 0;
    background-color: transparent;
    border: 0;
}

.vehicle-single .sidebar-widget .ui-selectmenu-button-closed .ui-icon {
    background-image: url(../img/Arrow\ -\ Down.png) !important;
    background-position: center;
    background-repeat: no-repeat;
}

.vehicle-single .sidebar-widget .ui-selectmenu-button-open .ui-icon {
    background-image: url(../img/ArrowTop.png) !important;
    background-position: center;
    background-repeat: no-repeat;
}

.vehicle-single .sidebar-widget .ui-selectmenu-text {
    font-family: 'SF Pro Display', sans-serif;
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: 0px;
    text-align: left;
    color: #3C3C3C !important;
}

.vehicle-single .sidebar-widget .ui-selectmenu-text {
    font-size: 16px;
}

.vehicle-single .sidebar-widget .ui-selectmenu-icon.ui-icon {
    margin-top: 0;
    margin-left: 10px;
    width: 18px;
    height: 18px;
}

.vehicle-single .sidebar-widget .time {
    min-width: 100px;
}

.vehicle-single .sidebar-widget .addres-filter {
    padding: 9px 15px;
    border-radius: 5px;
    border: 1px solid #D8D8D8;
    gap: 10px;
    width: 100%;
}

.vehicle-single .sidebar-widget .addres-filter input {
    width: 100%;
    border: 0;
    outline: 0;
    font-family: 'SF Pro Display', sans-serif;
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: 0px;
    text-align: left;
    color: #3C3C3C;
}

.vehicle-single .sidebar-widget .addres-filter input::placeholder {
    font-family: 'SF Pro Display', sans-serif;
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: 0px;
    text-align: left;
    color: #3C3C3C;
}

.vehicle-single .sidebar-widget .addres-filter input:focus {
    border: 0;
    outline: 0;
}

.vehicle-single .sidebar-widget input.addres-filter:disabled {
    background-color: #F3F3F3;
    border-color: #F3F3F3;
}

.vehicle-single .sidebar-widget input.addres-filter::placeholder {
    font-family: 'SF Pro Display', sans-serif;
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: 0px;
    text-align: left;
    color: #3C3C3C;
}

.vehicle-single .sidebar-widget input.addres-filter:disabled::placeholder {
    color: #666666;
}

.vehicle-single .sidebar-widget input.addres-filter {
    margin-bottom: 13px;
}

.vehicle-single .sidebar-widget input.addres-filter:focus {
    border: 1px solid #D8D8D8;
    outline: 0;
}

.vehicle-single .sidebar-widget .form-checkbox {
    margin-right: 10px;
    width: 20px;
    height: 20px;
}

.vehicle-single .sidebar-widget .checkbox-label {
    font-family: 'SF Pro Display', sans-serif;
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: 0px;
    text-align: left;
    color: #3C3C3C;
    cursor: pointer;
}

.vehicle-single .sidebar-widget .amount-wrapper {
    padding: 25px 0;
    border-top: 1px solid #EFEFF4;
}

.vehicle-single .sidebar-widget .amount-wrapper .vehicle-purchase-table {
    border-spacing: 15px;
    width: 100%;
}

.vehicle-single .sidebar-widget .amount-wrapper .vehicle-purchase-table tbody th,
.vehicle-single .sidebar-widget .amount-wrapper .vehicle-purchase-table tbody td {
    font-family: 'SF Pro Display', sans-serif;
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: 0px;
    text-align: left;
    color: #3C3C3C;
    padding: 0 0 15px;
}

.vehicle-single .sidebar-widget .amount-wrapper .vehicle-purchase-table tbody td {
    text-align: right;
}

.vehicle-single .sidebar-widget .amount-wrapper .vehicle-purchase-table tfoot {
    border-top: 1px solid #EFEFF4;
}

.vehicle-single .sidebar-widget .amount-wrapper .vehicle-purchase-table tfoot th,
.vehicle-single .sidebar-widget .amount-wrapper .vehicle-purchase-table tfoot td {
    font-family: 'SF Pro Display', sans-serif;
    font-size: 16px;
    font-weight: 700;
    line-height: 22px;
    letter-spacing: 0px;
    text-align: left;
    color: #3C3C3C;
    padding: 25px 0;
}

.vehicle-single .sidebar-widget .amount-wrapper .vehicle-purchase-table tfoot td {
    text-align: right;
}

.vehicle-single .sidebar-widget .amount-wrapper .book-btn {
    width: 100%;
    border-radius: 10px;
    background-color: #F8CC0D;
    color: #fff;
    font-family: 'SF Pro Display', sans-serif;
    font-size: 16px;
    font-weight: 700;
    line-height: 22px;
    letter-spacing: 0px;
    text-align: center;
    padding: 10px 0;
    border: 0;
    outline: 0;
}

/* vehicle info */
.vehicle-gallery {
    position: relative;
}

.vehicle-gallery .slick-track {
    display: flex;
}

.vehicle-gallery .slick-track .slick-slide {
    height: inherit;
}

.vehicle-gallery .slick-track .slick-slide>div {
    height: 100%;
}

.vehicle-gallery .gal-item {
    border-radius: 10px;
    width: 100%;
    aspect-ratio: 82/50;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgb(0 0 0 / 60%);
    overflow: hidden;
}

.vehicle-gallery .gal-item img {
    object-fit: cover;
    width: 100%;
    height: 100%;
}

.vehicle-gallery .owl-nav {
    position: absolute;
    top: 50%;
    width: 100%;
    transform: translateY(-50%);
    padding: 0 20px;
    display: flex;
    justify-content: space-between;
}

.vehicle-gallery .owl-nav button {
    width: 28px;
    height: 28px;
    background-color: #fff !important;
    opacity: 0.8;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.vehicle-gallery .owl-nav button span {
    color: transparent;
    background-position: center;
    background-repeat: no-repeat;
    width: 15px;
    height: 15px;
    background-size: contain;
}

.vehicle-gallery .owl-nav button.owl-prev span {
    background-image: url(../img/Arrowleft.png);
}

.vehicle-gallery .owl-nav button.owl-next span {
    background-image: url(../img/Arrowright.png);
}

.vehicle-gallery .owl-dots {
    position: absolute;
    width: 100%;
    bottom: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5px;
}

.vehicle-gallery .owl-dots .owl-dot {
    cursor: pointer;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background: #E9E9E9B2;
}

.vehicle-gallery .owl-dots .owl-dot.active {
    background: #FFFFFF;
}

/* .vehicle-gallery .owl-nav {
    position: absolute;
    top: 50%;
    width: 100%;
    transform: translateY(-50%);
    padding: 0 20px;
    display: flex;
    justify-content: space-between;
} */

.vehicle-gallery .slick-arrow {
    width: 28px;
    height: 28px;
    background-color: #fff !important;
    opacity: 0.8;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;

    position: absolute;
    z-index: 1;
    top: 50%;
    transform: translateY(-50%);
}

.vehicle-gallery .slick-arrow::before {
    color: transparent;
    background-position: center;
    background-repeat: no-repeat;
    width: 15px;
    height: 15px;
    background-size: contain;
    display: flex;
    margin: 0 auto;
}

.vehicle-gallery .slick-arrow.slick-prev {
    left: 20px
}

.vehicle-gallery .slick-arrow.slick-prev::before {
    background-image: url(../img/Arrowleft.png);
}

.vehicle-gallery .slick-arrow.slick-next {
    right: 20px
}

.vehicle-gallery .slick-arrow.slick-next::before {
    background-image: url(../img/Arrowright.png);
}

.vehicle-gallery .slick-dots {
    position: absolute;
    width: 100%;
    bottom: 20px;
    display: flex !important;
    align-items: center;
    justify-content: center;
    gap: 5px;
}

.vehicle-gallery .slick-dots li {
    margin: 0;
    width: 8px;
    height: 8px;
}

.vehicle-gallery .slick-dots li button {
    cursor: pointer;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background: #E9E9E9B2;
    padding: 0;
}

.vehicle-gallery .slick-dots li.slick-active button {
    background: #FFFFFF;
}

.vehicle-gallery .slick-dots button::before {
    display: none;
}

.vehicle-images-wrapper {
    position: relative;
}

.like-btn {
    position: absolute;
    right: 20px;
    top: 20px;
    z-index: 22;
}

.like-btn.liked svg path {
    fill: #F61E1E;
}

.vehicle-info {
    padding: 30px 0;
}

.vehicle-info .title {
    font-family: 'SF Pro Display', sans-serif;
    font-size: 32px;
    font-weight: 700;
    line-height: 22px;
    letter-spacing: 0px;
    text-align: left;
    color: #3C3C3C;
    margin-bottom: 10px;
}

.vehicle-info .category {
    font-family: 'SF Pro Display', sans-serif;
    font-size: 16px;
    font-weight: 500;
    line-height: 22px;
    letter-spacing: 0px;
    text-align: left;
    color: #666;
    margin-bottom: 10px;
}

.vehicle-info .rate {
    gap: 5px;
}

.vehicle-info .rate span {
    font-family: 'SF Pro Display', sans-serif;
    font-size: 16px;
    font-weight: 500;
    line-height: 22px;
    letter-spacing: 0px;
    text-align: left;
    color: #3C3C3C;
}

.vehicle-info .hosted-by-wrapper {
    margin: 30px 0;
}

.vehicle-info .hosted-by-wrapper .owner-wrapper .owner-img {
    width: 58px;
    height: 58px;
    border-radius: 50%;
    margin-right: 10px;
}

.vehicle-info .hosted-by-wrapper .owner-wrapper .owner-info .name {
    font-family: 'SF Pro Display', sans-serif;
    font-size: 16px;
    font-weight: 500;
    line-height: 22px;
    letter-spacing: 0px;
    text-align: left;
    color: #3C3C3C;
    margin-bottom: 3px;
}

.vehicle-info .hosted-by-wrapper .owner-wrapper .owner-info .owner-rate {
    font-family: 'SF Pro Display', sans-serif;
    font-size: 14px;
    font-weight: 500;
    line-height: 22px;
    letter-spacing: 0px;
    text-align: left;
    color: #666;
    margin-left: 1px;
    display: inline-block;
}

.vehicle-info .ctn-info-btn {
    padding: 7px 15px;
    border: 1px solid #D8D8D8;
    color: #3C3C3C;
    font-family: 'SF Pro Display', sans-serif;
    font-size: 14px;
    font-weight: 500;
    line-height: 22px;
    letter-spacing: 0px;
    text-align: left;
    border-radius: 30px;
    margin-left: 30px;
    cursor: pointer;
}

.vehicle-info .vehicle-description {
    margin-bottom: 20px;
}

.vehicle-info .vehicle-description p {
    font-family: 'SF Pro Display', sans-serif;
    font-size: 16px;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: 0px;
    text-align: left;
    color: #3C3C3C;
}

.vehicle-info h5 {
    font-family: 'SF Pro Display', sans-serif;
    font-size: 16px;
    font-weight: 700;
    line-height: 22px;
    letter-spacing: 0px;
    text-align: left;
    color: #3C3C3C;
    margin-bottom: 15px;
}

.equipment-container {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    height: 92px;
}

.equipment-item {
    flex-basis: 33%;
    max-width: max-content;
    display: inline-flex;
    align-items: center;
    gap: 10px;
    font-family: 'SF Pro Display', sans-serif;
    font-size: 16px;
    font-weight: 400;
    line-height: 19px;
    letter-spacing: 0px;
    text-align: left;
    color: #3C3C3C;
    margin-right: 100px;
}

.equipment-item:last-child {
    margin-right: 0;
}

.technical-features {
    margin-top: 30px;
}

.technical-features .feautures-container {
    gap: 20px;
}

.technical-features .feautures-container .features-item {
    border-bottom: 1px solid #EFEFF4;
    padding-bottom: 10px;
}

.technical-features .feautures-container .features-item .feature-name {
    font-family: 'SF Pro Display', sans-serif;
    font-size: 16px;
    font-weight: 400;
    line-height: 19px;
    letter-spacing: 0px;
    text-align: left;
    color: #666;
}

.technical-features .feautures-container .features-item .feature-value {
    font-family: 'SF Pro Display', sans-serif;
    font-size: 16px;
    font-weight: 500;
    line-height: 19px;
    letter-spacing: 0px;
    text-align: right;
    color: #3C3C3C;
}

.technical-features .feautures-container .collapse .features-item:first-child {
    padding-top: 0;
    padding-bottom: 10px;
    /* margin-bottom: 20px; */
}

.technical-features .feautures-container .collapse .features-item {
    padding-bottom: 10px;
}

.show-more-features-btn {
    text-decoration: underline;
    font-family: 'SF Pro Display', sans-serif;
    font-size: 16px;
    font-weight: 400;
    line-height: 19px;
    letter-spacing: 0px;
    text-align: left;
    color: #3C3C3C;
    border: 0 !important;
    outline: 0 !important;
    background-color: transparent;
}

.vehicle-single .location {
    margin-top: 30px;
}

.vehicle-single .location .location-map iframe {
    min-height: 477px;
}

.vehicle-single .reviews {
    margin-top: 30px;
}

.vehicle-single .reviews .review-item {
    margin-bottom: 20px;
}

.vehicle-single .reviews .review-item .review-item-wrapper {
    gap: 10px;
}

.vehicle-single .reviews .review-item:not(:last-child)::after {
    content: '';
    width: calc(100% - 105px);
    border-bottom: 1px solid #EFEFF4;
    display: block;
    margin-top: 20px;
}

.vehicle-single .reviews .review-item .review-author-image img {
    width: 56px;
    height: 56px;
    border-radius: 50%;
}

.reviews .review-item .stars {
    gap: 5px;
    margin-bottom: 5px;
}

.reviews .review-item .review-name {
    font-family: 'SF Pro Display', sans-serif;
    font-size: 14px;
    font-weight: 500;
    line-height: 22px;
    letter-spacing: 0px;
    text-align: left;
    color: #3C3C3C;
    display: inline-block;
}

.reviews .review-item .review-date {
    font-family: 'SF Pro Display', sans-serif;
    font-size: 14px;
    font-weight: 500;
    line-height: 22px;
    letter-spacing: 0px;
    text-align: left;
    color: #666666;
}

.reviews .review-item .review-info {
    gap: 10px;
}

.reviews .review-item .review-text {
    font-family: 'SF Pro Display', sans-serif;
    font-size: 16px;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: 0px;
    text-align: left;
    color: #3C3C3C;
}

.recomendation-car {
    padding-bottom: 50px;
}

.recomendation-car .section-title-wrapper h5 {
    font-family: 'SF Pro Display', sans-serif;
    font-size: 16px;
    font-weight: 700;
    line-height: 22px;
    letter-spacing: 0px;
    text-align: left;
    color: #3C3C3C;
    margin-bottom: 15px;
}

.recomendation-car .section-title-wrapper .underlined-btn {
    font-family: 'SF Pro Display', sans-serif;
    font-size: 16px;
    font-weight: 400;
    line-height: 19px;
    letter-spacing: 0px;
    text-align: left;
    color: #3C3C3C;
    text-decoration: none;
    padding-bottom: 1px;
    border-bottom: 1px solid #3C3C3C;
    display: block;
}

/* vehicle single item */
.recomendation-car .vehicle {
    border-radius: 10px;
    overflow: hidden;
    box-shadow: 0px 4px 4px 0px #EBEBEB;
    text-decoration: none;
    display: block;
}

.recomendation-car .vehicle .vehicle-info {
    padding: 20px 20px 16px;
}

.recomendation-car .vehicle .vehicle-info .head {
    gap: 18px;
}

.recomendation-car .vehicle-image {
    position: relative;
}

.recomendation-car .like-btn {
    position: absolute;
    top: 16px;
    right: 15px;
    z-index: 22;
}

.recomendation-car .like-btn.liked svg path {
    fill: #F61E1E;
}

.recomendation-car .vehicle .vehicle-info .head h4 {
    font-family: 'SF Pro Display', sans-serif;
    font-size: 16px;
    font-weight: 700;
    line-height: 22px;
    letter-spacing: 0px;
    text-align: left;
    margin-top: 3px;
    color: #3C3C3C;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    max-width: 16ch;
}

.recomendation-car .vehicle .vehicle-info .rate-wrapper {
    gap: 5px;
    font-family: 'SF Pro Display', sans-serif;
    font-size: 14px;
    font-weight: 500;
    line-height: 22px;
    letter-spacing: 0px;
    text-align: left;
    color: #3C3C3C;
}

.recomendation-car .vehicle-info .horizontal-divider {
    border: 1px solid #EFEFF4;
    display: block;
    margin: 6px 0 13px;
}

.recomendation-car .vehicle-info .price-wrapper {
    font-family: 'SF Pro Display', sans-serif;
    font-size: 16px;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: 0px;
    text-align: left;
    color: #3C3C3C;
}

.recomendation-car .vehicle-info .price-wrapper .seperator {
    margin: 0 3px;
    font-weight: 700;
}

.recomendation-car .vehicle-info .price-wrapper .total-price {
    margin-right: 3px;
}

/* single-item-end */