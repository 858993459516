@font-face {
    font-family: 'SF Pro Display';
    src: url(../fonts/SFPRODISPLAYREGULAR.OTF);
    font-style: normal;
    font-weight: 400;
    font-display: swap;
}

@font-face {
    font-family: 'SF Pro Display';
    src: url(../fonts/SFPRODISPLAYBOLD.OTF);
    font-style: normal;
    font-weight: 700;
    font-display: swap;
}

@font-face {
    font-family: 'SF Pro Display';
    src: url(../fonts/SFPRODISPLAYMEDIUM.OTF);
    font-style: normal;
    font-weight: 500;
    font-display: swap;
}
