.about-1-main {
    padding: 0;
}

.about-1-container {
    max-width: 1235px;
    margin-left: auto;
    margin-right: auto;
}

.about-1-section {
    background-image: url(../img/about-1-back.png);
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
    height: calc(100vh - 73px);
}

.ab-1-content-title {
    font-family: 'SF Pro Display';
    font-style: normal;
    font-weight: 700;
    font-size: 42px;
    line-height: 46px;
    text-align: center;
    color: #FFFFFF;
    padding-bottom: 20px;
}

.ab-1-content-text {
    font-family: 'SF Pro Display';
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    text-align: center;
    text-transform: lowercase;
    color: #FFFFFF;
    max-width: 382px;
    padding-bottom: 20px;
}

.ab-1-content-button {
    font-family: 'SF Pro Display';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 22px;
    color: #3C3C3C;
    padding: 7px 65px 7px 65px;
    border: none;
    background: #FFFFFF;
    border: 1px solid #D8D8D8;
    border-radius: 30px;
    text-decoration: none;
}
.ab-1-content-button:hover{
    color: #3C3C3C;
}