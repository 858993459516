@media screen and (max-width:991.9px) {
    .home {
        flex-wrap: wrap;
    }
    .home .homepage-slider-section {
        width: 100%;
    }
    .home .homepage-slider-section .owl-full-height {
        height: 380px;
    }
    .home .homepage-slider-section .slick-dots {
        bottom: 20px;
    }
    .home .homepage-find-section {
        width: 100%;
        background: white;
    }
    .home .homepage-map-section {
        width: 100%;
        height: 400px;
    }
    .home .login-section {
        padding-right: 0!important;
        height: calc(100vh - 64px);
        padding-top: 32px;
        padding-bottom: 32px;
    }
    .home .filter-title {
        font-family: 'SF Pro Display', sans-serif;
        font-size: 16px;
        font-weight: 700;
        line-height: 22px;
        letter-spacing: 0px;
        text-align: left;
        color: #3C3C3C;
        margin-bottom: 10px;
    }

    .home .date-input-group {
        padding: 9px 15px;
        border-radius: 5px;
        border: 1px solid #D8D8D8;
        gap: 15px;
        gap: 0;
        position: relative;
        /* z-index: 100; */
    }

    .home .date-input-group .divider {
        width: 1px;
        min-width: 1px;
        height: 22px;
        background-color: #efeff4;
        margin-left: 14px;
    }

    .home .date-input-group>.ui-front {
        padding-left: 14px;
    }

    
    .home .date-input-group>.ui-front.active {
        z-index: 102;
    }

    .home .hasDatepicker {
        width: 192px;
        transform: translateY(2px);
        padding-bottom: 0;
        border: 0;
        padding-left: 49px;
        background-color: transparent;

    }

    .home .date {
        position: relative;
        margin-left: -15px;
    }
    .home .date.opened {
        z-index: 102;
    }

    .home .date::after {
        content: '';
        margin-left: 13px;
        display: inline-flex;
        width: 18px;
        height: 18px;
        align-self: center;
        background-position: center;
        background-size: contain;
        background-repeat: no-repeat;
        background-image: url(../img/ArrowDownBig.png);
        position: absolute;
        top: 50%;
        right: 0;
        transform: translateY(-10px);
    }

    .home .date.closed::after {
        background-image: url(../img/ArrowDownBig.png) !important;
    }

    .home .date.opened::after {
        background-image: url(../img/ArrowTopBig.png) !important;
    }

    .home .date .icon {
        position: absolute;
        top: 0;
        left: 15px;
    }

    .home .ui-selectmenu-button.ui-button {
        width: unset;
        padding: 0;
        border-radius: 0;
        background-color: transparent;
        border: 0;
    }

    .home .ui-selectmenu-menu li.ui-menu-item {
        border-radius: 5px;
        padding: 4px;
    }

    .home .ui-selectmenu-menu li.ui-menu-item:hover {
        background-color: #F4F4F4;
    }

    .home .ui-selectmenu-button.ui-button {
        width: unset;
        padding: 0;
        border-radius: 0;
        background-color: transparent;
        border: 0;
    }

    .home .ui-selectmenu-button-closed .ui-icon {
        background-image: url(../img/Arrow\ -\ Down.png) !important;
        background-position: center;
        background-repeat: no-repeat;
    }

    .home .ui-selectmenu-button-open .ui-icon {
        background-image: url(../img/ArrowTop.png) !important;
        background-position: center;
        background-repeat: no-repeat;
    }

    .home .ui-selectmenu-text {
        font-family: 'SF Pro Display', sans-serif;
        font-size: 14px;
        font-weight: 400;
        line-height: 22px;
        letter-spacing: 0px;
        text-align: left;
        color: #3C3C3C !important;
    }

    .home .ui-selectmenu-text {
        font-size: 16px;
    }

    .home .ui-selectmenu-icon.ui-icon {
        margin-top: 0;
        margin-left: 10px;
        width: 18px;
        height: 18px;
    }

    .home .search-link {
        width: 100%;
        border-radius: 5px;
        background-color: #F8CC0D;
        color: #fff;
        font-family: 'SF Pro Display', sans-serif;
        font-size: 17px;
        font-weight: 700;
        line-height: 22px;
        letter-spacing: 0px;
        text-align: center;
        padding: 10px 15px;
        border: 0;
        outline: 0;
        text-decoration: none;
    }
    .ui-datepicker {
        z-index: 102!important;
    }
}