.homapege-navbar-1 {
    justify-content: center;
    align-items: center;
    flex-grow: 1;
    gap: 40px;
    list-style-type: none;
}

.homapege-navbar-1 li.nav-item a {
    font-family: 'SF Pro Display', sans-serif;
    font-size: 14px;
    font-weight: 400;
    line-height: 17px;
    letter-spacing: 0px;
    text-align: left;
    text-decoration: none;
    color: #fff;
}

/* .homapege-navbar-1 li.nav-item:hover a{
    font-weight: 700;
} */
.homapege-navbar-1 li.nav-item.active a {
    font-weight: 700;
}

.home-header {
    padding-top: 60px;
}

.online-chat {
    text-decoration: none;
    gap: 10px;
    align-items: center;
}

.online-chat h4 {
    font-family: 'SF Pro Display', sans-serif;
    font-size: 20px;
    font-weight: 700;
    line-height: 28px;
    letter-spacing: 0px;
    text-align: left;
    color: #3C3C3C;
}

.online-chat p {
    font-family: 'SF Pro Display', sans-serif;
    font-size: 12px;
    font-weight: 400;
    line-height: 14px;
    letter-spacing: 0px;
    text-align: left;
    color: #3C3C3C;
}

.navbar-collapse {
    flex-basis: 0;
    flex-grow: 0;
    align-items: center;
}

.login a {
    gap: 8px;
    padding: 8px 18px;
    border: 1px solid #D8D8D8;
    border-radius: 30px;
    text-decoration: none;
}

.login a span {
    font-family: 'SF Pro Display', sans-serif;
    font-size: 14px;
    font-weight: 700;
    line-height: 22px;
    letter-spacing: 0px;
    text-align: left;
    color: #3C3C3C;
}

.right {
    gap: 20px;
}

.lang-swithcer {
    display: flex;
    align-items: center;
    padding: 9px 12px;
    border-radius: 30px;
    border: 1px solid #D8D8D8;
}

.lang-swithcer .dropdown-menu {
    inset: 0px auto auto 0px !important;
    transform: translate(-90px, 40px) !important;
    background-color: transparent;
}

.lang-swithcer .dropdown-toggle::after {
    background-image: url(../img/Arrow.svg);
    background-repeat: no-repeat;
    background-size: contain;
    border: 0;
    letter-spacing: 0;
    margin-left: 13px;
    width: 10px;
    height: 6px;
}

.navbar {
    background-color: transparent;
}

.navbar-toggler {
    padding: 0;
    border: 0;
    outline: 0;
    box-shadow: none;
    width: 40px;
    height: 40px;
    background-color: transparent;
}

.navbar-toggler:focus {
    padding: 0;
    border: 0;
    outline: 0;
    box-shadow: none;
}

.navbar-toggler-icon {
    background-image: url(../img/heroicons-outline_menu-alt-2.png);
    background-color: transparent;
}

.navbar {
    padding: 0;
}

.offcanvas {
    padding: 20px 20px 20px 30px;
}

.offcanvas-header {
    justify-content: flex-end;
    padding: 0;
}

.offcanvas-header .btn-close {
    padding: 0;
    margin: 0;
    background-image: url(../img/carbon_close.png);
    width: 40px;
    height: 40px;
    background-size: contain;
    background-repeat: no-repeat;
}

.offcanvas-header .btn-close:focus {
    border: 0;
    outline: 0;
    box-shadow: none;
    transform: none;
}

.offcanvas-body {
    margin-top: 50px;
    padding: 0;
}

.offcanvas-body .navbar-nav {
    gap: 15px;
}

.offcanvas-body .navbar-nav li.nav-item a {
    font-family: 'SF Pro Display', sans-serif;
    font-size: 14px;
    font-weight: 400;
    line-height: 17px;
    letter-spacing: 0px;
    text-align: left;
    color: #3C3C3C;
    padding: 0;
}

.offcanvas-body .navbar-nav li.nav-item.active a {
    font-weight: 700;
}

header {
    position: fixed;
    width: 100%;
    z-index: 222;
}

.offcanvas-backdrop.show {
    opacity: 1;
    background-color: rgba(60, 60, 60, 0.3);
    backdrop-filter: blur(2px);
}

.offcanvas,
.offcanvas-lg,
.offcanvas-md,
.offcanvas-sm,
.offcanvas-xl,
.offcanvas-xxl {
    --bs-offcanvas-width: 280px;
}

.copyright {
    font-family: 'SF Pro Display', sans-serif;
    font-size: 12px;
    font-weight: 500;
    line-height: 14px;
    letter-spacing: 0px;
    text-align: left;
}

.offcanvas-footer .social {
    gap: 16px;
    margin-bottom: 20px;
}

/* slider */
.homepage-slider-section {
    width: 50%;
    background-color: #adadad;
}

.homepage-slider-section .slider-item {
    width: 100%;
    height: 100%;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
}

.homepage-slider-section .owl-full-height {
    height: 100vh;
}

.homepage-slider-section .owl-item,
.owl-stage-outer,
.owl-stage {
    height: 100%;
}

.homepage-slider-section .slick-list,
.homepage-slider-section .slick-track,
.homepage-slider-section .slick-slide,
.homepage-slider-section .slick-slide>div {
    height: 100%;
}

.homepage-slider-section .owl-dots {
    position: absolute;
    bottom: 60px;
    gap: 14px;
    display: flex;
}

.homepage-slider-section .slick-dots {
    position: absolute;
    bottom: 60px;
    gap: 14px;
    display: flex;
    width: auto;
}

.homepage-slider-section .owl-carousel .owl-dots button.owl-dot {
    width: 12px;
    height: 12px;
    border: 2.5px solid #fff;
    border-radius: 50%;
    background-color: #fff;
}

.homepage-slider-section .slick-dots li button {
    width: 12px;
    height: 12px;
    border: 2.5px solid #fff;
    border-radius: 50%;
    background-color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
}

.homepage-slider-section .slick-dots li button::before {
    content: "";
    background: transparent;
    display: block;
    width: 10px;
    min-width: 10px;
    height: 10px;
    position: unset;
    border-radius: 50%;
}

.homepage-slider-section .slick-dots li.slick-active button::before {
    background: rgb(33, 37, 41);
}

.homepage-slider-section .owl-carousel .owl-dots button.owl-dot.active {
    background-color: transparent;
}

.homepage-slider-section .slider-item h2 {
    font-family: 'SF Pro Display', sans-serif;
    font-size: 38px;
    font-weight: 700;
    line-height: 46px;
    letter-spacing: 0px;
    text-align: left;
    color: #fff;
    margin-bottom: 20px;
}

.homepage-slider-section .slider-item p {
    font-family: 'SF Pro Display', sans-serif;
    font-size: 20px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0px;
    text-align: left;
    color: #fff;
}


/*  */
.homepage-find-section {
    width: 50%;
}

.homepage-find-section .rent-footer {
    background-color: #F8CC0D;
}

.rent-wrap {
    padding: 39px 40px 38px;
    background-color: #FADA1C;
}

.rent-wrap a {
    font-family: 'SF Pro Display', sans-serif;
    font-size: 20px;
    font-weight: 700;
    line-height: 24px;
    letter-spacing: 0px;
    text-align: left;
    color: #fff;
    margin-bottom: 5px;
    text-decoration: none;
}

.rent-wrap p {
    font-family: 'SF Pro Display', sans-serif;
    font-size: 12px;
    font-weight: 400;
    line-height: 14px;
    letter-spacing: 0px;
    text-align: left;
    color: #fff;
}

.pricing-wrap {
    padding: 30px 40px;
    gap: 40px;
}

.pricing-wrap .price-wrap p {
    font-family: 'SF Pro Display', sans-serif;
    font-size: 12px;
    font-weight: 400;
    line-height: 14px;
    letter-spacing: 0px;
    text-align: left;
    color: #fff;
}

.pricing-wrap .price-wrap .price {
    font-family: 'SF Pro Display', sans-serif;
    font-size: 38px;
    font-weight: 700;
    line-height: 45px;
    letter-spacing: 0px;
    text-align: left;
    color: #fff;
    position: relative;
}

.pricing-wrap .price-wrap .price sup {
    font-family: 'SF Pro Display', sans-serif;
    font-size: 24px;
    font-weight: 700;
    line-height: 1;
    letter-spacing: 0px;
    text-align: left;
    color: #fff;
    margin: 0 5px;
}

.pricing-wrap .price-wrap .price sub {
    font-family: 'SF Pro Display', sans-serif;
    font-size: 12px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0px;
    text-align: left;
    color: #fff;
    position: absolute;
    right: 7px;
    display: block;
    bottom: 0px;
    letter-spacing: 0.05em;
}

.model h4 {
    font-family: 'SF Pro Display', sans-serif;
    font-size: 20px;
    font-weight: 700;
    line-height: 24px;
    letter-spacing: 0px;
    text-align: left;
    color: #fff;
    margin-bottom: 10px;
}

.model .types {
    gap: 20px;
}

.fuel-type,
.transmisson-type {
    gap: 10px;
    align-items: center;
}

.fuel-type p,
.transmisson-type p {
    font-family: 'SF Pro Display', sans-serif;
    font-size: 12px;
    font-weight: 400;
    line-height: 14px;
    letter-spacing: 0px;
    text-align: left;
    color: #fff;
}

/*  */
.homepage-find-section {
    background-image: url(../img/Welcome\ back.png);
    background-position: calc(50% + 50px) calc(50% - 105px);
    background-repeat: no-repeat;
    background-color: #F2F2F2;
}

.homepage-find-section .find-wraper {
    padding-left: 100px;
}

.homepage-find-section .find-wraper h5 {
    font-family: 'SF Pro Display', sans-serif;
    font-size: 20px;
    font-weight: 700;
    line-height: 22px;
    letter-spacing: 0px;
    text-align: left;
    color: #3C3C3C;
    margin-bottom: 15px;
    margin-top: 0;
    border: unset;
    padding: 0;
}

.homepage-find-section .find-wraper .find-car {
    padding: 0;
    background: #fff;
    gap: 15px;
    box-shadow: 10px 20px 30px 0px #3C3C3C1A;
    align-items: center;
    max-width: 530px;
}

.homepage-find-section .find-wraper .location {
    padding: 7px 0 7px 15px;
    gap: 5px;
    align-items: center;
    flex-grow: 1;
}

.homepage-find-section .find-wraper .location span {
    font-family: 'SF Pro Display', sans-serif;
    font-size: 14px;
    font-weight: 700;
    line-height: 22px;
    letter-spacing: 0px;
    text-align: left;
    color: #666;
}

.homepage-find-section .find-wraper .start-date,
.homepage-find-section .find-wraper .end-date {
    align-items: center;
    gap: 5px;
}

.homepage-find-section .find-wraper .start-date {
    border-right: 1px solid #D8D8D8;
    padding-right: 15px;
}

.homepage-find-section .find-wraper .start-date span,
.homepage-find-section .find-wraper .end-date span {
    font-family: 'SF Pro Display', sans-serif;
    font-size: 10px;
    font-weight: 700;
    letter-spacing: 0px;
    text-align: left;
    color: #666666;
    min-width: 56px;
}

.homepage-find-section .find-wraper .search {
    background-color: #F8CC0D;
    padding: 12px 25px;
    cursor: pointer;
    border: none;
    outline: none;
}

.homepage-find-section .find-wraper .checkbox-wraper {
    gap: 10px;
    margin-top: 15px;
    align-items: center;
}

.homepage-find-section .find-wraper .checkbox-wraper .checkbox {
    width: 16px;
    height: 16px;
    accent-color: #666;
    border: 1px solid #666;
    background-color: transparent;
    border-radius: 2px;
    cursor: pointer;
}

.homepage-find-section .find-wraper .checkbox-wraper label {
    font-family: 'SF Pro Display', sans-serif;
    font-size: 14px;
    font-weight: 500;
    line-height: 22px;
    letter-spacing: 0px;
    text-align: left;
    color: #666;
}