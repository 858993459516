.busyDaysWrapper .add-btn {
    color: var(--white, #FFF);
    font-family: 'SF Pro Display', sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 22px;
    padding: 10px 16.5px;
    /* background-color: var(--black, #3C3C3C); */
    border: none;
    border-radius: 5px;
    user-select: none;
}

.busyDaysWrapper .remove-btn {
    /* background-color: #EC4E5F; */
    color: var(--white, #FFF);
    text-align: center;
    font-family: 'SF Pro Display', sans-serif;
    font-size: 16px;
    font-weight: 400;
    line-height: 1;
    border: none;
    border-radius: 5px;
    padding: 10px 15px;
}

.busyDaysWrapper .date-wrapper {
    align-items: center;
    gap: 20px;
}

.busyDaysWrapper .start-date img,
.busyDaysWrapper .end-date img {
    width: 40px;
    height: 40px;
}

.busyDaysWrapper .start-date,
.busyDaysWrapper .end-date {
    text-align: left;
    gap: 5px;
}

.busyDaysWrapper .dropdown-center {
    min-width: 130px;
}

.busyDaysWrapper .date {
    display: inline-flex;
    align-items: center;
    border: 1px solid #D8D8D8 !important;
    border-radius: 5px;
    padding: 6.79px 5px;
}

.busyDaysWrapper .date input {
    padding: 0;
    border: 0 !important;
    width: 100%;
}

.busyDaysWrapper .date.invalid {
    border-color: rgb(220, 53, 69)!important;
}

.date-wrapper .busy-days-body {
    gap: 20px;
    margin: 20px 0;
}
.date-wrapper .busy-days-body.add-busy-days{
    margin-top: 0;
}

.date-wrapper .busy-days-body label span.label{
    color: var(--black, #3C3C3C);
    font-family: 'SF Pro Display', sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 22px;
    margin-bottom: 10px;
    display: block;
}
.date-wrapper {
    padding: 12px 15px;
    border-radius: 5px;
    border: 1px solid #D8D8D8;
}

.date-wrapper .actions-wrapper {
    display: flex;
    gap: 10px;
}

.date-wrapper .actions-wrapper button {
    border: 0;
    outline: 0;
    background-color: transparent;
    color: var(--black, #3C3C3C);
    text-align: right;
    font-family: 'SF Pro Display', sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    padding-bottom: 2px;
    border-bottom: 1px solid #3C3C3C;
}

.date-wrapper p {
    color: #666;
    font-family: 'SF Pro Display', sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
}

.date-wrapper .update-btn {
    border-radius: 5px;
    background: var(--black, #3C3C3C);
    padding: 10px 15px;
    color: var(--White, #FFF);
    font-family: 'SF Pro Display', sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 22px;
    border: 0;
    outline: 0;
}

.date-wrapper .btn {
    border-radius: 5px;
    padding: 10px 15px;
    font-family: 'SF Pro Display', sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 22px;
    border: 0;
    outline: 0;
}