*{
    box-sizing: border-box;
    margin: 0;
    padding: 0;
}

.hero-block{
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.35) 0%, rgba(0, 0, 0, 0.35) 100%), url('../img/order-result.png'), lightgray -0.187px -32px / 100.026% 124.583% no-repeat;
    width: 100vw;
    height: 100vh;
    background-size: cover;
    aspect-ratio: 16 / 9;
    text-align: center;
    display: flex;
    margin: auto;
}

.order-n{
    padding: 54px 63px 58px 63px;
    display: inline-block;
    margin: auto;
    border-radius: 10px;
    background: rgba(0, 0, 0, 0.35);
}

.order-n h2{
    color: #FFF;
    text-align: center;
    font-family: SF Pro Display,sans-serif;
    font-size: 42px;
    font-style: normal;
    font-weight: 700;
    line-height: 46px; /* 109.524% */
}

.clock-p{
    color: var(--white, #FFF);
    font-family: SF Pro Display,sans-serif;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-decoration-line: underline; 
}

.status-text{
    color: var(--white, #FFF);
    text-align: center;
    font-family: SF Pro Display,sans-serif;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin-top: 15px;
    margin-bottom: 15.5px;
}

.line{
    border: 1px solid rgba(255, 255, 255, 0.30);
}

.find-more{
    color: var(--white, #FFF);
    text-align: center;
    font-family: SF Pro Display,sans-serif;
    font-size: 20px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
    margin-top: 15px;
    margin-bottom: 20px;
}

.learn-more{
    color: #3C3C3C !important;
    font-family: SF Pro Display,sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 22px; 
    text-decoration: none;
    padding: 7px 67px;
    border-radius: 30px;
    border: 1px solid #D8D8D8;
    background: #FFF;
    margin-top: 20px;
}

.status-text .status{
    color: #FADA1C;
    font-family: SF Pro Display,sans-serif;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}