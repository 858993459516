.finance-section .balance.main-balance {
    padding: 24px;
    border: 1px solid #E1E1E1;
    border-radius: 10px;
    background-image: url('../img/Line\ background.png');
    background-repeat: no-repeat;
    background-position: right bottom;
    background-color: #F4F4F4;
}

.finance-section .balance {
    background-color: #F4F4F4;
    padding: 24px;
    border: 1px solid #E1E1E1;
    border-radius: 10px;
    height: 100%;
}

.balance-block-header {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
}

.balance .balance-block-body .title {
    font-family: 'SF Pro Display', sans-serif;
    color: var(--gray-900, #101828);
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 16px;
    margin-bottom: 12px;
}

.balance .balance-block-body .amount {
    font-family: 'SF Pro Display', sans-serif;
    color: var(--gray-800, #1D2939);
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 1;
    margin-bottom: 22px;
}

.balance .balance-block-body .status {
    color: var(--gray-700, #344054);
    font-family: 'SF Pro Display', sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    margin-bottom: 0;
}

.balance-block-body {
    display: flex;
    flex-direction: column;
    padding-top: 28px;
}

.upcoming-payment {
    padding: 24px;
    border-radius: 10px;
    border: 1px solid #E1E1E1;
    background: #F8CC0D;
}


.withdrawal-money-text>p {
    color: var(--black, #3C3C3C);
    font-family: 'SF Pro Display', sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin: 0;
}

.withdrawal-money-text {
    display: flex;
    justify-content: space-between;
    padding-bottom: 30px;
}

.withdrawal-money {
    padding-top: 70px;
}

.wrapper {
    padding: 10px;
}

.withdrawal-money-border {
    width: 100%;
    height: 1px;
    background: #EFEFF4;
    margin-bottom: 30px;
}

.withdrawal-money-button {
    color: var(--white, #FFF);
    font-family: 'SF Pro Display', sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 22px;
    /* 137.5% */
    padding: 10px 39px;
    background-color: var(--black, #3C3C3C);
    border: none;
    border-radius: 5px;
}

.added-withdraw {
    padding: 30px 0;
    border-top: 1px solid #EFEFF4;
    border-bottom: 1px solid #EFEFF4;
    margin: 20px 0;
}

.added-withdraw p {
    font-family: 'SF Pro Display', sans-serif;
    font-size: 16px;
    font-weight: 400;
    line-height: 19px;
    letter-spacing: 0px;
    text-align: left;
    color: #3C3C3C;
}

.withdraw-add-wrapper p {
    font-family: 'SF Pro Display', sans-serif;
    font-size: 16px;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: 0px;
    text-align: left;
    color: #666;
}

.withdraw-add-wrapper input {
    padding: 10px 15px;
    border-radius: 5px;
    border: 1px solid #D8D8D8;
    font-family: 'SF Pro Display', sans-serif;
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: 0px;
    text-align: left;
    color: #3C3C3C;
    margin-bottom: 20px;
    width: 295px;
}

.withdraw-add-wrapper input:focus {
    outline: 0;
    border: 1px solid #000;
}

.withdraw-add-wrapper input::placeholder {
    font-family: 'SF Pro Display', sans-serif;
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: 0px;
    text-align: left;
    color: #666;
}

.withdraw-buttons-wrapper {
    display: flex;
    gap: 20px;
}

.finance-history-section {
    padding: 0 0 20px;
}

.finance-history-section .finance-history-table {
    width: 100%;
    border-collapse: collapse;
    border-spacing: 0 21px;
}

.finance-history-section .finance-history-table tr {
    border-bottom: 1px solid #EFEFF4;
}

.finance-history-section .finance-history-table tr td {
    color: var(--Secondary, #666);
    font-family: 'SF Pro Display', sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 22px;
}

.finance-history-section .finance-history-table tr th {
    color: var(--black, #3C3C3C);
    font-family: 'SF Pro Display', sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 22px;
}

.finance-history-section .finance-history-table tr td,
.finance-history-section .finance-history-table tr th {
    padding: 20px 0;
}

.finance-history-section .finance-history-table tr td:first-child,
.finance-history-section .finance-history-table tr th:first-child {
    padding-left: 20px;
    text-align: left;
}

.finance-history-section .finance-history-table tr td:last-child,
.finance-history-section .finance-history-table tr th:last-child {
    padding-right: 20px;
    text-align: right;
}

.finance-history-section .finance-history-table tr td.renter-img {
    gap: 15px;
}

.finance-history-section .finance-history-table tr td.renter-img img {
    width: 32px;
    height: 32px;
    border-radius: 50%;
}