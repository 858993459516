/* contacts page */
.contact-main-help-centers>.container>.help-center-contacts>.contact-city>div>img {
    width: 100%;
}

@media screen and (max-width:1400px) {

    .vehicle-header>.col-12>.d-flex.justify-content-between>.flex-grow-1.d-flex.header-left.align-items-center>.filters-wrap {
        gap: 2px;
    }
}


@media screen and (max-width:1200px) {
    .vehicle-header>.col-12>.d-flex.justify-content-between>.flex-grow-1.d-flex.header-left.align-items-center>.filters-wrap {
        display: none !important;
    }
}


@media screen and (max-width: 993px) {
    .contact-main>.contact>.wrapper>.contact-main-help-centers>.container>.help-center-contacts {
        flex-direction: column;

    }

    .contact-main>.contact>.wrapper>.contact-main-help-centers>.container>.help-center-contacts .contact-city {
        width: 100%;
    }

    .contact-main>.contact>.wrapper>.container>.contact-main-text-content>.contact-main-h1 h1 {
        width: 100% !important;
    }

    @media screen and (max-width:575px) {
        .contact-main>.contact>.wrapper>.container>.contact-main-text-content>.contact-main-h1 h1 {
            font-size: 28px;
        }
    }

}

/* contacts page end */




/* footer media */

.footer-br {
    display: none;
}

@media screen and (max-width:1346px) {
    footer .container .row .col.d-flex.footer-wrapper {
        flex-direction: column-reverse;
    }

    footer .container .row .col.d-flex.footer-wrapper .footer-left-col {
        text-align: center;
    }

    footer .container .row .col.d-flex.footer-wrapper .footer-left-col .social-wrapper {
        justify-content: center;
    }

}

@media screen and (max-width:991.9px) {

    footer .footer-wrapper .menu-footer-block {
        order: 2 !important;
    }

    footer .footer-wrapper .download-app-column {
        order: 2;
    }

    footer .footer-wrapper .subscribe-col {
        order: 0;
        flex-basis: 100%;

    }

    footer .footer-wrapper .subscribe-col .subscribe-wrapper.js_subscribe_wrapper {
        justify-content: space-between;
    }

    footer .footer-wrapper .footer-right-col {
        flex-wrap: wrap;
    }
}

@media screen and (max-width:767.9px) {
    .footer-br {
        display: block;
    }

    footer .footer-wrapper .footer-right-col {
        gap: 20px !important;
        font-size: 13px;
        justify-content: flex-start;
    }

    footer .footer-wrapper .footer-right-col .download-app-column .app-links-wrapper {
        gap: 10px;
        /* flex-direction: column; */
    }

    footer .subscribe-col .description {
        max-width: unset;
    }
}
/* footer media end */




/* about-2 media */

.about-2-section1-content-br1,
.about-2-section1-content-br2 {
    display: none;
}

main {
    padding: 0 !important;
}

.about-2-section4 {
    padding-bottom: 150px;
}

.sec-2-title-mobile {
    display: none;
}

@media screen and (max-width:1200px) {
    main>.about-2-section1>.container>.about-2-section1-content {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .about-2-section1-content-br1,
    .about-2-section1-content-br2 {
        display: block;
    }

    .about-2-section2 .about-2-section2-content {
        flex-direction: column;
        align-items: center;
    }

    .about-2-section2 .about-2-section2-content .ab-2-content-text-sec2 {
        text-align: center;
    }

    .ab-2-content-image-sec2 {
        margin-right: 0 !important;
    }

    .sec-2-title-mobile {
        display: block;
        color: #3C3C3C;
        text-align: center;
        font-family: SF Pro Display;
        font-size: 25px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        margin-bottom: 22px;
    }

    .sec-2-title {
        display: none;
    }

    .about-2-section2 .about-2-section2-content .ab-2-content-text-sec2 p {
        font-size: 20px;
        line-height: normal;
        margin-top: 22px;
    }

    .about-2-section3>.container>.about-2-section3-content {
        padding: 0 !important;
    }
}

@media screen and (max-width:992px) {
    .about-2-section3>.container>.about-2-section3-content>.about-2-sec3-row-1 {
        flex-direction: column;
        gap: 20px;
    }

    .about-2-section3>.container>.about-2-section3-content>.about-2-sec3-row-2 {
        flex-direction: column;
        align-items: center;
        gap: 20px;
        padding-top: 20px !important;
    }

    .about-2-section4>.container>.about-2-section4-content {
        flex-direction: column;
        gap: 22px;
        align-items: center;
    }

    .about-2-section4>.container>.about-2-section4-content .ab-2-sec4-left {
        text-align: center;
    }

    .about-2-section4>.container>.about-2-section4-content>.ab-2-sec4-left .ab-dark-button {
        display: none;
    }

    .about-2-section4>.container>.about-2-section4-content>.ab-2-sec4-right {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .about-2-section4>.container>.about-2-section4-content>.ab-2-sec4-right .ab-dark-button-mobile {
        color: var(--white, #FFF);
        font-family: SF Pro Display;
        font-size: 15px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        background-color: #F8CC0D;
        padding: 10px 15px;
        text-decoration: none;
        border-radius: 5px;
        margin-top: 22px;
    }

    .about-2-section4 {
        padding-bottom: 40px;
    }

    .about-2-section2-content {
        padding: 0 !important;
    }

    .about-2-section2-content .ab-2-content-image-sec2 img {
        width: 100%;
    }

    .about-2-section1-content p {
        max-width: 100%;
    }

}

@media screen and (max-width:500px) {
    .about-2-section1-content h1 {
        font-size: 28px !important;
    }

    .about-2-section1-content p {
        font-size: 15px !important;

    }

    .sec-2-title-mobile {
        font-size: 15px;
    }

    .about-2-section2 {
        padding-bottom: 22px !important;
    }

    .about-2-section3-content h2 {
        padding-bottom: 22px !important;
    }

    .about-2-section3>.container>.about-2-section3-content>.about-2-sec3-row-1 {
        gap: 0;
    }

    .about-2-section3 {
        padding-bottom: 40px !important;
    }



    .row1-col1 p {
        max-width: 100% !important;
        color: #666 !important;
        text-align: center;
        font-family: SF Pro Display;
        font-size: 13px !important;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }

    .about-2-section3-content h2 {
        color: var(--black, #3C3C3C);
        text-align: center;
        font-family: SF Pro Display;
        font-size: 17px !important;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }

    .ab-2-sec4-left h2 {
        font-size: 20px !important;
        text-align: center !important;
    }

    .ab-2-sec4-left p {
        font-size: 15px !important;
    }

    .ab-2-sec4-right img {
        width: 100%;
    }
}

/* about-2 media end */




/* about-1 media */

.about-1-section {
    height: 100vh !important;
}

/* about-1 media end */








/* privacy policy media */

main>section.privacy-policy {
    height: 100%;
}

@media screen and (max-width:768px) {
    main>section.privacy-policy h2 {
        font-size: 20px !important;
    }

    main>section.privacy-policy p {
        font-size: 15px;
    }
}


/* privacy policy media end */



/* vehicles list media */

html {
    width: 100%;
    /* overflow-x: hidden; */
}

body {
    width: 100%;
    min-height: 100vh;
    /* overflow: hidden; */
}

/* vehicles list media end */