.my-vehicles {
    padding: 40px 0;
}

.my-vehicles h1 {
    font-family: 'SF Pro Display', sans-serif;
    font-size: 20px;
    font-weight: 700;
    line-height: 22px;
    letter-spacing: 0px;
    text-align: left;
    color: #3C3C3C;
}

.vehicles-wrapper {
    padding: 36px 0 25px;
}

.vehicles-wrapper .vehicle-item {
    padding: 25px 0;
    border-bottom: 1px solid #EFEFF4;
}

.vehicles-wrapper .vehicle-item .vehicle-info-wrapper {
    gap: 20px;
    align-items: center;
}

.vehicles-wrapper .vehicle-item .vehicle-info-wrapper img {
    width: 198px;
    border-radius: 10px;
}

.vehicles-wrapper .vehicle-item .vehicle-info-wrapper h6,
.vehicles-wrapper .vehicle-item .vehicle-info-wrapper .title {
    font-family: 'SF Pro Display', sans-serif;
    font-size: 16px;
    font-weight: 700;
    line-height: 22px;
    letter-spacing: 0px;
    text-align: left;
    color: #3C3C3C;
    margin-bottom: 4px;
    text-decoration: none;
}

.vehicles-wrapper .vehicle-item .btn-group {
    align-items: center;
}

.vehicles-wrapper .vehicle-item .vehicle-info-wrapper p {
    font-family: 'SF Pro Display', sans-serif;
    font-size: 16px;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: 0px;
    text-align: left;
    color: #3C3C3C;
    margin-bottom: 4px;
}

.vehicles-wrapper .vehicle-item .vehicle-info-wrapper p.tags {
    max-width: 279px;
    color: #666666;
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    white-space: pre-wrap;
}

.vehicles-wrapper .vehicle-item .def-btn {
    width: 42px;
    height: 42px;
    border-radius: 5px;
    border: 1px solid #D8D8D8;
    padding: 19px 13px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #F4F4F4;
    gap: 2px;
}

.vehicles-wrapper .vehicle-item .def-btn::before {
    display: none;
}

.vehicles-wrapper .vehicle-item .def-btn span {
    width: 4px;
    height: 4px;
    border-radius: 50%;
    background-color: #666;
}

.vehicles-wrapper .vehicle-item .dropdown-menu {
    border-radius: 10px;
    border: 0;
    box-shadow: 0px 4px 4px 0px #ECECEC;
}

.vehicles-wrapper .vehicle-item .def-btn-item {
    padding: 7px 10px;
    border-radius: 10px;
    background: #fff;
    font-family: 'SF Pro Display', sans-serif;
    font-size: 16px;
    font-weight: 500;
    line-height: 22px;
    letter-spacing: 0px;
    text-align: left;
    color: #3C3C3C;
    cursor: pointer;
}

.vehicles-wrapper .vehicle-item .def-btn-item a {
    text-decoration: none;
    color: unset;
    display: block;
}

.vehicles-wrapper .vehicle-item .del-vehicle-btn {
    color: #ec4e5f;
}

.save-btn {
    font-family: 'SF Pro Display', sans-serif;
    font-size: 16px;
    font-weight: 500;
    line-height: 22px;
    letter-spacing: 0px;
    text-align: center;
    background-color: #3C3C3C;
    color: #fff;
    border: 1px solid #3C3C3C;
    padding: 10px 18px;
    border-radius: 5px;
    box-shadow: none;
    outline: none;
    text-decoration: none;
}

.save-btn:hover {
    color: #fff;
}

#deleteVehicleModal .modal-content {
    border: 0;
    max-width: 400px;
    border-radius: 10px;
}

#deleteVehicleModal .img-btn {
    font-family: 'SF Pro Display', sans-serif;
    font-size: 16px;
    font-weight: 500;
    line-height: 22px;
    letter-spacing: 0px;
    text-align: center;
    padding: 10px 15px;
    border-radius: 5px;
    border: 1px solid #D8D8D8;
    background-color: #fff;
    color: #3C3C3C;
}

#deleteVehicleModal .modal-body h5 {
    font-family: 'SF Pro Display', sans-serif;
    font-size: 20px;
    font-weight: 700;
    line-height: 22px;
    letter-spacing: 0px;
    text-align: left;
    color: #3C3C3C;
}

#deleteVehicleModal .modal-footer {
    border: 0;
    padding: 0 30px 40px;
}

#deleteVehicleModal .modal-header {
    border: 0;
    padding: 15px 15px 0;
}

.vehicle-item.hidden .vehicle-info-wrapper {
    opacity: 0.4;
    pointer-events: none;
}

.vehicle-item .active-btn {
    display: none;
}

.vehicle-item.hidden .active-btn {
    display: block;
}

.vehicle-item .hide-btn {
    display: block;
}

.vehicle-item.hidden .hide-btn {
    display: none;
}