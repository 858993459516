section.privacy-policy {
    background-image: url(../img/terms-and-privacy/pexels-audrius-strikaitis-109030981.png);
    padding: 170px 0;
    margin: 0 auto;
    background-repeat: no-repeat;
    background-size: cover;
    height: calc(100vh - 73px);
}

section.privacy-policy h2,
section.privacy-policy p {
    margin-bottom: 20px;
}

section.privacy-policy h2 {
    font-size: 42px;
    font-weight: 700;
    line-height: 46px;
}

section.privacy-policy p {
    font-size: 20px;
    line-height: 24px;
}

section.privacy-policy a {
    text-decoration: none;
    color: #3C3C3C;
    background-color: #ffffff;
    padding: 7px 65px;
    border: 1px solid #D8D8D8;
    border-radius: 30px;
    font-size: 14px;
    line-height: 22px;
}