.homepage-slider-section .slick-dots,
.p-s-container {
    padding-left: 10px;
}

.p-e-container {
    padding-right: 10px;
}

@media (min-width: 576px) {

    .homepage-slider-section .slick-dots,
    .p-s-container {
        padding-left: calc((100vw - 540px) / 2 + 10px);
    }

    .p-e-container {
        padding-right: calc((100vw - 540px) / 2 + 10px);
    }
}

@media (min-width: 768px) {

    .homepage-slider-section .slick-dots,
    .p-s-container {
        padding-left: calc((100vw - 720px) / 2 + 10px);
    }

    .p-e-container {
        padding-right: calc((100vw - 720px) / 2 + 10px);
    }
}

@media (min-width: 992px) {

    .homepage-slider-section .slick-dots,
    .p-s-container {
        padding-left: calc((100vw - 960px) / 2 + 10px);
    }

    .p-e-container {
        padding-right: calc((100vw - 960px) / 2 + 10px);
    }
}

@media (min-width: 1200px) {

    .homepage-slider-section .slick-dots,
    .p-s-container {
        padding-left: calc((100vw - 1140px) / 2 + 10px);
    }

    .p-e-container {
        padding-right: calc((100vw - 1140px) / 2 + 10px);
    }
}

@media (min-width: 1400px) {

    .homepage-slider-section .slick-dots,
    .p-s-container {
        padding-left: calc((100vw - 1260px) / 2 + 10px);
    }

    .p-e-container {
        padding-right: calc((100vw - 1260px) / 2 + 10px);
    }
}


.filters-form .ui-selectmenu-menu {
    max-height: 200px;
    overflow: auto;
    overflow-x: hidden;
    scrollbar-color: rgb(60 60 60 / 60%) rgb(60 60 60 / 20%);
    scrollbar-width: thin;
}

.filters-form .ui-selectmenu-menu::-webkit-scrollbar-track {
    border-radius: 3px;
    background-color: rgb(60 60 60 / 20%);
}

.filters-form .ui-selectmenu-menu::-webkit-scrollbar {
    border-radius: 3px;
    width: 6px;
    height: 6px;
    background-color: rgba(255, 106, 0, 0);
}

.filters-form .ui-selectmenu-menu::-webkit-scrollbar-thumb {
    border-radius: 3px;
    background-color: rgb(60 60 60 / 60%);
}

.cursor-pointer {
    cursor: pointer;
}


[disabled] {
    opacity: 0.6;
}

[disabled],
[disabled] * {
    pointer-events: none;
}


@-webkit-keyframes loading {
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

@keyframes loading {
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

.loading {
    width: 64px;
    height: 64px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    user-select: none;
}

.loading .spinner {
    content: "";
    width: 85%;
    height: 85%;
    display: block;
    border-radius: 50%;
    border: 7px solid #d7d7d7;
    border-left-color: #767676;
    -webkit-animation: loading 1.5s linear infinite;
    animation: loading 1.5s linear infinite;
}


.chat-popup {
    z-index: 100000;
    position: fixed;
    right: 0;
    bottom: 0;
    height: 80vh;
    width: 720px;
}

.chat-popup .close {
    position: absolute;
    top: 0;
    right: 0;
    width: 32px;
    height: 32px;
    padding: 0;
}

.chat-popup .close::before,
.chat-popup .close::after {
    content: "";
    position: absolute;
    left: 8px;
    width: 22px;
    height: 2px;
    display: block;
    background-color: #000;
    transform-origin: left;
}

.chat-popup .close::before {
    top: 6.5px;
    transform: rotate(45deg);
}

.chat-popup .close::after {
    bottom: 6.5px;
    transform: rotate(-45deg);
}

@media (max-width: 767.9px) {
    .chat-popup {
        width: auto;
        height: calc(100vh - 67px);
    }

    .chat-popup .close {
        top: 8px;
        right: 4px;
    }
}

.after-none::after {
    display: none !important;
}

.rate {
    user-select: none;
}

.rate .star.disabled {
    opacity: 0.5;
}

a.ui-state-default:hover {
    background-color: #F4F4F4;
}

a.ui-state-default.ui-state-active {
    background: #f8cc0d !important;
    color: white !important;
}

.page-wrapper {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
}

.primary-button {
    border-radius: 8px;
    background: #F8CC0D;
    padding: 14px 16px;
    color: #FFF;
    text-align: center;
    font-family: 'SF Pro Text', sans-serif;
    font-size: 17px;
    font-style: normal;
    font-weight: 600;
    line-height: 22px;
}


.secondary-button {
    font-family: 'SF Pro Display', sans-serif;
    font-size: 16px;
    font-weight: 500;
    line-height: 22px;
    letter-spacing: 0px;
    text-align: center;
    background-color: #3C3C3C;
    color: #fff;
    border: 1px solid #3C3C3C;
    padding: 10px 18px;
    border-radius: 5px;
    box-shadow: none;
    outline: none;
    text-decoration: none;
}

.primary-input {
    border-radius: 8px;
    background-color: #EFEFF4;
    color: #000;
    font-family: 'SF Pro Text', sans-serif;
    font-size: 17px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
    padding: 11px 16px;
    margin-bottom: 8px;
    border: 1px solid #fff;
}

.verification-banner {
    height: 60px;
    display: flex;
    align-items: center;
    background: #3c3c3ca3;
    color: white;
    user-select: none;
}

.close-btn {
    position: absolute;
    width: 24px;
    height: 24px;
    cursor: pointer;
    top: 0;
    box-shadow: 0px 0px 8px -4px black;
    background: #0000006b;
}

.close-btn::before {
    content: "";
    position: absolute;
    width: 24px;
    height: 2px;
    top: 50%;
    left: 50%;
    display: block;
    background-color: white;
    transform-origin: center;
    transform: translate(-12px, -1px) rotate(45deg);
}

.close-btn::after {
    content: "";
    position: absolute;
    width: 24px;
    height: 2px;
    top: 50%;
    left: 50%;
    display: block;
    background-color: white;
    transform-origin: center;
    transform: translate(-12px, -1px) rotate(-45deg);
}



.custom-pill {
    padding: 2px 20px;
    border-radius: 30px;
    background-color: rgba(0, 196, 140, 0.12);
    border: 1px solid #00C48C;
    font-family: 'SF Pro Display', sans-serif;
    font-size: 14px;
    font-weight: 500;
    line-height: 22px;
    letter-spacing: 0px;
    text-align: left;
    color: #3C3C3C;
    text-transform: capitalize;
}

.custom-pill.yellow {
    background-color: rgba(248, 204, 13, 0.12);
    border: 1px solid #F8CC0D;
}

.custom-pill.blue {
    background-color: rgba(0, 122, 255, 0.12);
    border: 1px solid #007AFF;
}

.custom-pill.purple {
    background-color: rgba(121, 63, 223, 0.12);
    border: 1px solid #793FDF;
}

.custom-pill.red {
    background-color: rgba(236, 78, 95, 0.12);
    border: 1px solid #EC4E5F;
}

.ui-menu.overflow {
    max-height: 400px;
    scrollbar-color: rgb(60 60 60 / 60%) rgb(60 60 60 / 20%);
    scrollbar-width: thin;
}

.ui-menu.overflow::-webkit-scrollbar-track {
    border-radius: 3px;
    background-color: rgb(60 60 60 / 20%);
}

.ui-menu.overflow::-webkit-scrollbar {
    border-radius: 3px;
    width: 6px;
    height: 6px;
    background-color: rgba(255, 106, 0, 0);
}

.ui-menu.overflow::-webkit-scrollbar-thumb {
    border-radius: 3px;
    background-color: rgb(60 60 60 / 60%);
}
